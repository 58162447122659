.hcp-support-nav {
	
	@include media-breakpoint-up(sm) {
		.pox-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 8em;
			width: 100%;
		}
	}
	
	
}

.hcp-resources {
	#access {
		
		@include media-breakpoint-down(md) {
			font-size: 0.875rem;
		}
		
	}
}