.clinical-results-tabs,
.clinical-results-footer {
	
	.tab-content {
		align-items: center;
		border-radius: $border-radius;
		flex-direction: column;
		font-size: 0.875rem;
		padding: 1rem;
		text-align: center;
		
		.icon {
			width: 4em;
			height: 4em;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: inherit;
			
			.icon {
				width: 6em;
				height: 6em;
			}
		}
	}
	
	.tab-heading { 
		font-size: 1.375rem;
	}
	
	a {
		background: lighten($gray-100, 5%);
		border: 1px solid $primary-light;
		border-radius: $border-radius;
		display: block;
		flex: 1;
		padding: 1px;
		transition: all 0.5s ease;
		
		&:hover {
			background: white;
			border-color: $primary;
		}
	}
	
	.active-tab {
		border: 2px solid $primary;
		border-radius: $border-radius;
		flex: 1;
		
		.tab-heading {
			color: black;
		}
	}
	
}

.clinical-results-footer {
	.tab-content {
		
		.icon {
			width: 3em;
			height: 3em;
		}
	}
}

.hcp-prophy {
	
	.efficacy-graphic {
		
		margin-top: -1rem;
		
		@include media-breakpoint-up(md) {
			margin-top: -10.6%;
		}
	}
	
}