.site-footer {
	padding-bottom: 90px;
	
	.isi-page & {
		padding-bottom: 0;
	}
}


.isi-footer {
	position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

.btn-isi {
	background-color: $primary;
	border-top: 1px solid white;
	box-shadow: 0 -2px 8px 0 rgba(0,0,0,0.2);
	color: white;
	display: block;
	font-size: 16px;
	font-weight: 700;
	padding: 0.5rem;
	text-align: center;
	transition: all 0.5s ease-out;
	width: 100%;
	
	&:hover {
		background-color: #003663;
		background-position: center 50%;
		color: white;
		text-decoration: none;
	}
}