.how-diagram {
	
	background: url("/img/coagadex-diagram-vertical_r1.svg") no-repeat left top;
	background-size: 100%;
	display: flex;
	flex-direction: column;
	font-size: 3.75vw;
	font-weight: bold;
	line-height: 1.25;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	
	.step-1,
	.step-2,
	.step-3,
	.step-4 {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 54%;
	}
	
	.step-1 {
		height: calc( (100vw - 2rem) * 0.49705764 );
	}
	
	.step-2 {
		height: calc( (100vw - 2rem) * 0.578125 );
	}
	
	.step-3 {
		height: calc( (100vw - 2rem) * 0.43142361 );
	}
	
	.step-4 {
		align-items: flex-start;
		padding-top: calc( (100vw - 2rem) * 0.63194444 );
		padding-left: 0;
		
		div {
			&:first-child {
				padding-right: 1rem;
				
				.step-4-heading {
					letter-spacing: -1px;
				}
			}
			&:last-child {
				padding-left: 1rem;
			}
		}
	}
	
	ul {
		list-style-type: disc;
		margin-left: 0;
		padding-left: 1.5em;
	}
	
	.step-4-heading {
		border-bottom: 1px solid $gray-800;
		font-size: 1em;
		font-weight: bold;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}
	
	@include media-breakpoint-up(sm) {
		
		font-size: 21px;
		width: 33.75rem;
	
		.step-1 {
			height: 268.411125px;
		}
		
		.step-2 {
			height: 312.1875px;
		}
		
		.step-3 {
			height: 232.968749px;
		}
		
		.step-4 {
			align-items: flex-start;
			padding-top: 341.249997px;
			padding-left: 0;
			
			div {
				&:first-child {
					padding-right: 1rem;
					
					.step-4-heading {
						letter-spacing: -1px;
					}
				}
				&:last-child {
					padding-left: 1rem;
				}
			}
		}
		
	}
	
	
	@include media-breakpoint-up(md) {
		
		background: url("/img/coagadex-diagram-horizontal_r1.svg") no-repeat left top;
		background-size: 100%;
		flex-direction: row;
		font-size: 1.5625vw;
		margin-top: -3rem;
		min-height: calc( (100vw - 5rem) * 0.40789474 );
		width: 100%;
		
		.step-1,
		.step-2,
		.step-3,
		.step-4 {
			flex-direction: column;
			align-items: stretch; // for IE
			height: auto;
		}
		
		.step-1,
		.step-2,
		.step-3 {
			padding-left: 1rem;
			padding-right: 1rem;
			padding-top: 29.333%;
			text-align: center;
		}
		
		.step-1 {
			width: 18.75%;
		}
		
		.step-2 {
			
			width: 21.5%;
		}
		
		.step-3 {
			width: 16.25%;
		}
		
		.step-4 {
			padding: 0 0 0 1rem;
			width: 43.5%;
			
			div {
				&:first-child {
					padding-left: 0;
					padding-right: 0;
					//padding-bottom: 2.25rem;
					min-height: 17.125vw;
					
					.step-4-heading {
						letter-spacing: 0;
						margin-top: -2.25em;
					}
				}
				&:last-child {
					padding-left: 0;
				}
			}
		}
		
		ul {
			padding-left: 57.5%;
		}
		
	}
	
	@include media-breakpoint-up(lg) {
		margin-top: -5rem;
	}
	
	@include media-breakpoint-up(xl) {
		font-size: 1rem;
		min-height: 560px;
	}
	
	@media(min-width: 1472px) {
		.step-4 {
			div {
				&:first-child {
					min-height: 252px;
				}
			}
		}
	}
	
}






