.text-survey-magenta {
	color: #e41b6b !important;
}


.survey-blood-drop {
	display: flex;
	background-image: url(/img/survey-2022/blood-drop.svg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	color: white;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 2.5em;
	height: 3em;
}

.survey-table {
	th {
		border-bottom: 4px solid $primary-dark;
		color: $primary-dark;
		padding: 0.5em 0;
	}
	
	td {
		padding: 1.5em 0;
	}
	
	.border-top {
		border-top: 1px dotted $gray-500;
	}
}

.col-qol {
	@include media-breakpoint-up(lg) {
		flex: 62.5% !important;
		max-width: 62.5% !important;
		padding-right: 0 !important;
	}
}

.col-hwbi {
	
	@include media-breakpoint-up(lg) {
		flex: 37.5% !important;
		max-width: 37.5% !important;
		padding-left: 2% !important;
	}
	
	
	.img-fluid {
		max-width: 50%;
		
		@include media-breakpoint-up(lg) {
			max-width: 100%;
		}
	}
}