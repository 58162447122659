.site-header {
	position: sticky;
	top: 0;
	z-index: 1000;
}

.header-nav {
	height: calc(100dvh - 4rem);
	overflow: auto;
	
	@include media-breakpoint-up(md) {
		height: auto;
		overflow: visible;
	}
}

// adjust anchor links to account for sticky header
html {
	scroll-snap-type: y proximity;
	scroll-padding-top: 4rem;
	overflow-y: scroll;

	@include media-breakpoint-up(md) {
		scroll-padding-top: 10.5em;
	}

	@include media-breakpoint-up(lg) {
		scroll-padding-top: 9.55555556em;
	}
	
	@include media-breakpoint-up(xl) {
		scroll-padding-top: 9.2em;
	}
}


.mobile-header {
	background: $light;
	color: white;
	line-height: 1;
	
	.mobile-header-logo {
	    font-size: 20px;
	    line-height: 22px;
	    height: 4rem;
	    margin: 0;
	    padding: 0.5rem 0 0 1rem;
		
		a {
			text-decoration: none;
		}
	}
	
	.btn {
		line-height: 1;
		text-decoration: none;
	}
}
.header-logo-wrapper {
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 30%;
	position: relative;
}

.header-logo-addon {
	align-self: stretch;
	border-left: 1px solid $primary;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 1.5rem;
}

.nav-main {
	
	background-color: $primary;
	
	@include media-breakpoint-up(md) {
		font-size: 0.8125rem;
	}
	
	@include media-breakpoint-up(lg) {
		font-size: 0.88888889rem;
	}
	
	@include media-breakpoint-up(xl) {
		font-size: 0.9rem;
	}
	
	.nav-link {
		color: white;
		padding: 0.5em 1em;
		
		&:hover,
		&:focus {
			background: darken($blue, 5%);
		}
		
		//&[data-state="active"],
		&[aria-current="page"] {
			background: darken($blue, 10%);
		}
		
		@include media-breakpoint-up(md) {
			padding: 0.5em;
		}
		
		@include media-breakpoint-up(lg) {
			padding: 0.5em 1em;
		}
	}
	
	.hcp & {
		
		background-color: darken($blue, 10%);
		
		.nav-link {
			
			&:hover,
			&:focus {
				background: darken($blue, 5%);
			}
			
			//&[data-state="active"],
			&[aria-current="page"] {
				background: $cyan;
			}
		}
	}
	
	.nav {
		
		display: block;
		
		@include media-breakpoint-up(md) {
		
			display: flex;
			align-items: stretch;
			flex-wrap: nowrap;
			justify-content: stretch;
			margin-left: -1rem;
			margin-right: -1rem;
			min-height: 60px;
			
			.nav-item {
				align-items: stretch;
				display: flex;
				flex: 1 0 0%;
				
				&.home,
				&.nav-search {
					flex: 0 1 4em;
				}
				
				&:hover {
					.hover-menu {
						display: block;
					}
				}
			}
			
			.nav-link {
				align-items: center;
				display: flex;
				flex: 1;
				justify-content: center;
				text-align: center;
				
				.nav-label {
					display: block;
				}
				
				&.nav-group {
					.triangle {
						display: inline-block;
						margin-left: .255em;
						vertical-align: middle;
						border-top: .3em solid;
						border-right: .3em solid transparent;
						border-bottom: 0;
						border-left: .3em solid transparent;
					}
				}
			}
			
			
		}
	}
			
	.hover-menu {
		
		display: block;
		
		.menu-item {
			display: block;
			color: white;
			padding: 0.5em 1em 0.5em 2.5em;
			
			&[aria-current="page"] {
				background: darken($blue, 10%);
			}
		}
		
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 98%;
			left: 0;
			z-index: 1000;
			display: none;
			float: left;
			min-width: 100%;
			padding: .5rem 0;
			margin: 0;
			font-size: 1rem;
			color: #212529;
			text-align: left;
			list-style: none;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid rgba(0,0,0,.15);
			border-radius: .25rem;
			
			.menu-item {
				
				width: 100%;
				padding: .25rem 1.5rem;
				clear: both;
				font-weight: 400;
				color: $primary;
				text-align: inherit;
				white-space: nowrap;
				background-color: transparent;
				border: 0;
				
				&[aria-current="page"] {
					background: transparent;
					color: #000;
					cursor: default;
					font-weight: bold;
					
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		
	}
	
	.nav-item {
		&:last-child {
			.hover-menu {
				@include media-breakpoint-up(md) {
					left: auto;
					right: 0;
				}
			}
		}
	}
}

.branding {
	background-color: white;
}

.nav-secondary {
	background-color: $primary;
	text-align: left;
	
	@include media-breakpoint-up(md) {
		background-color: transparent;
		font-size: 0.75rem;
		
		.nav {
			justify-content: flex-end;
		}
	}
	
	.nav-link {
		
		
		@include media-breakpoint-down(sm) {
			color: white;
			padding: 0.5em 1em;
			
			&:hover,
			&:focus {
				background: $gray-900;
			}
			
			&[data-state="active"],
			&[aria-current="page"] {
				background: $gray-900;
			}
		}
		
		@include media-breakpoint-up(md) {
			margin: 0 0 0.25rem 0;
			padding: 0 0.5rem;
			text-decoration: underline;
			text-decoration-thickness: 1px;
			text-underline-offset: 2px;
			
			&[data-state="active"],
			&[aria-current="page"] {
				color: black;
			}
			
			+ .nav-link {
				border-left: 1px solid $gray-200;
			}
			
			&:hover {
				text-decoration-color: $red;
				text-decoration-thickness: 2px;
			}
		}
		
		@include media-breakpoint-up(lg) {
			margin: 0 0 0.5rem 0;
			padding: 0 0.75rem;
		}
		
		
	}
}

.header-tools {
	
	width: 100%;
	
	a,
	button {
		font-weight: 500;
	}
	
	@include media-breakpoint-up(md) {
		line-height: 1.1;
		text-align: right;
		
		a,
		button {
			//font-size: 1.125em;
		}
		
		a {
			text-decoration: underline;
			text-decoration-thickness: 1px;
			text-underline-offset: 2px;
			
			&:hover {
				text-decoration-color: $red;
				text-decoration-thickness: 2px;
			}
		}
	}
	
	@include media-breakpoint-up(lg) {
		line-height: 1.2;
		text-align: right;
		//width: 33%;
		
		a,
		button {
			font-size: 1.125em;
		}
	}
	
	.share {
		
		button {
			
			color: $primary;
			
			span {
				text-decoration: underline;
				text-underline-offset: 2px;
				text-decoration-thickness: 1px;
			}
			
			&:hover {
				color: #00213b;
				span {
					text-decoration-color: $red;
					text-decoration-thickness: 2px;
				}
			}
		}
		
		.icon {
			font-size: 1rem;
			margin-left: 0.25rem;
			top: -2px;
		}
		
		@include media-breakpoint-up(md) {
			border-left: 1px solid $gray-200;
		}
	}
	
}


.copy-status {
	transition: opacity ease-in-out 0.5s;
}