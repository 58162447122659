
.rounded-bottom-lg {
	border-bottom-left-radius: 0.75rem!important;
	border-bottom-right-radius: 0.75rem!important;
}

.border-left-dotted {
	border-left-style: dotted !important;
}

.border-top-dotted {
	border-top-style: dotted !important;
	border-top-width: 2px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-8 {
	border-width: 8px !important;
}

.border-10 {
	border-width: 10px !important;
}

.img-round {
	border-radius: 50%;
}

.z10 {
	z-index: 10;
}

.border-y-6x {
	border-top: 6px solid black;
	border-bottom: 6px solid black;
}

// adds a rule (border) next to text
.inline-rule-middle {
	display: flex;
	align-items: flex-start;
	text-align: left;
	
	&:after {
		background-color: currentcolor;
		content: "";
		flex-grow: 1;
		height: 1px;
		min-width: 1rem;
		margin: auto auto auto 1rem;
	}
}


.video-btn {
	display: block;
	position: relative;
	
	.icon-play {
		color: white;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -3rem 0 0 -3rem;
		width: 6rem;
		height: 6rem;
		filter: drop-shadow(2px 2px 8px $gray-600);
		transition: all ease-in 0.2s;
		opacity: 0.9;
		
		@include media-breakpoint-up(md) {
			margin: -4rem 0 0 -4rem;
			width: 8rem;
			height: 8rem;
		}
		
		&.icon-play-sm {
			
			@include media-breakpoint-up(md) {
				margin: -3rem 0 0 -3rem;
				width: 6rem;
				height: 6rem;
			}
		}
	}
	
	&:hover {
		
		.icon-play {
			transform: scale(1.2);
			opacity: 1;
		}
	}
}



.bg-md {
	background-color: #d2dbe7;
}

.bg-primary-light {
	background: $primary-light;
}

.bg-primary-light-alt {
	background: $primary-light-alt;
}

.bg-primary-lighter {
	background: $primary-lighter;
}

.bg-primary-lightest {
	background: $primary-lightest;
}

.bg-primary-bright {
	background: $primary-bright;
}

.bg-primary-brighter {
	background: $primary-brighter;
}

.bg-primary-dark {
	background: $primary-dark;
}


.modal-hd {
	max-width: 1920px;
}


.opacity-0 {
	opacity: 0;
}

// misc.
.max-w-150 {
	max-width: 150px;
	height: auto;
}

.brackets-magenta {
	border: 4px solid magenta;
	
	.brackets-content {
		background-color: white;
		margin: -5px 2rem;
	}
}