* {
	fill: currentColor;
}
.icon {
	height: 1em;
	width: 1em;
	position: relative;
	top: 1px;
	
	&.icon-lg {
		height: 1.5em;
		width: 1.5em;
	}
	
	&.icon-2x {
		height: 2em;
		width: 2em;
	}
	
	&.icon-3x {
		height: 3em;
		width: 3em;
	}
	
	&.icon-4x {
		height: 4em;
		width: 4em;
	}
	
	&.icon-5x {
		height: 5em;
		width: 5em;
	}
	
	&.icon-6x {
		height: 6em;
		width: 6em;
	}
	
	&.icon-8x {
		height: 8em;
		width: 8em;
	}
}


$iconsizes: (
  1x: 1em,
  lg: 1.5em,
  2x: 2em,
  3x: 3em,
  4x: 4em,
  5x: 5em,
  6x: 6em,
  8x: 8em,
  10x: 10em,
  12x: 12em,
  16x: 16em,
  20x: 20em
);

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		
		@each $size, $value in $iconsizes {
		  .icon#{$infix}-#{$size} { 
			  width: $value !important;
			  height: $value !important;
		  }
		}
			
	}
}
