table {
	&.border-white {
		th, td {
			border: 1px solid #ffffff;
		}
	}
	
	&.border-right-none {
		th, td {
			&:last-child {
				border-right: none;
			}
		}
	}
}

.border-bottom-left-radius {
	border-bottom-left-radius: 10px;
}

caption {
	caption-side: top;
	font-weight: bold;
}

.hcp-table {
	border-collapse:separate;
	border-spacing: 0;
	width: 100%;
	
	th {
		font-weight: 700;
		
	}
	
	thead th {
		vertical-align: bottom;
	}
	
	th,
	td {
		border: 1px solid $gray-300;
		font-size: 11px;
		line-height: 1.25;
		padding: 0.5em;
		
		&.border-top-primary {
			border-top-color: $primary;
		}
		&.border-right-primary {
			border-right-color: $primary;
		}
		&.border-bottom-primary {
			border-bottom-color: $primary;
		}
		&.border-left-primary {
			border-left-color: $primary;
		}
		
		@include media-breakpoint-up(sm) {
			font-size: 14px;
		}
		
		@include media-breakpoint-up(md) {
			font-size: 16px;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
	}
	
	// stripe rows
	&.bpl-table-striped {
		tbody td {
			background: $primary-lightest;
		}
		
		tbody > tr:nth-child(odd) td {
			background: $primary-lighter;
		}
	}
	
	// bottom left corner rounded
	.rounded-bottom-left {
		border-bottom-left-radius: 10px;
	}
	
	
}