.how-treated-bullet-1 {
	
	@include media-breakpoint-up(lg) {
		letter-spacing: -1px;
	}
	
}

.test-equation {
	.icon {
		width: 5rem;
		height: 5rem;
		
		@include media-breakpoint-up(lg) {
			width: 3rem;
			height: 3rem;
		}
		
		@include media-breakpoint-up(xl) {
			width: 5rem;
			height: 5rem;
		}
	}
}