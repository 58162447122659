// Colors

$white:    #fff !default;
$gray-100: #e6e8eb;
$gray-200: #c2c6cc;
$gray-300: #9fa7b1;
$gray-400: #808998;
$gray-500: #5f6c7f;
$gray-600: #465266;
$gray-700: #2f3947;
$gray-800: #1c2533;
$gray-900: #0a1019;
$black:    #000104;


$blue:    #004b87;
$red:     #b32028;
$cyan:    #00aeef;
$dark:    $gray-700;

$primary-light-alt:		change-color($blue, $lightness: 79%, $saturation: 53%);
$primary-light:		#D5DDEE;
$primary-lighter:		#E2EBF5;
$primary-lightest:		#f2f7fc;
$primary-bright: #CDE9F9;
$primary-brighter: #e8f6ff;
$primary-dark: darken($blue, 5%);

$light: $primary-lightest;


// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-800;



// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//$enable-caret:                                true !default;
//$enable-rounded:                              true !default;
$enable-shadows:                              true;
//$enable-gradients:                            true;
//$enable-transitions:                          true !default;
//$enable-prefers-reduced-motion-media-query:   true !default;
//$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
//$enable-grid-classes:                         true !default;
//$enable-pointer-cursor-for-buttons:           true !default;
//$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true;
//$enable-validation-icons:                     true !default;
//$enable-deprecation-messages:                 true !default;


// Grid columns
$grid-gutter-width:           32px;

// border radius
$border-radius:               .5rem;
$border-radius-lg:            .75rem;
$border-radius-sm:            .25rem;



// Typography
$font-size-base:              1rem; // Assumes the browser default, typically `16px`

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.625;
$h3-font-size:                $font-size-base * 1.375;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base;
//$h6-font-size:                $font-size-base !default;

$headings-font-weight:   600;
$enable-responsive-font-sizes: true;

$container-max-widths: (
  xl: 1472px
);

$btn-border-radius:								$border-radius-sm;
$input-border-radius:         					$border-radius-sm;
$custom-checkbox-indicator-border-radius:       $border-radius-sm;