.awareness-modal {
	
	.drop-shadow {
		filter: drop-shadow(3px 4px 6px $gray-700);
	}
	
	.pull-up {
		margin-top: -33%;
	}
	
	.date-graphic {
		margin-top: -1rem;
		shape-outside: circle(50%);
	}
	
	.info {
		padding-top: 36%;
	}
	
	.btn-close {
		position: absolute;
		top: -0.25em;
		right: -0.625em;
		
		@include media-breakpoint-up(sm) {
			top: -1.5em;
			right: -1.5em;
		}
	}
	
	.patient-photo {
		border-top: 4px solid $blue;
		border-right: 2px solid $blue;
		border-bottom: 4px solid $blue;
		border-left: 2px solid $blue;
		&:first-child {
			border-left-width: 4px;
		}
		&:last-child {
			border-right-width: 4px;
		}
	}
	
	.john {
		background-color: $primary;
		margin: 4rem -1rem 1.5rem;
		padding: 3.5rem 1rem 1rem;
		position: relative;
		
		.photo {
			position: absolute;
			top: -3rem;
			width: 6rem;
			left: 50%;
			margin-left: -3rem;
			
		}
		
		.icon-play {
			width: 3rem;
			height: 3rem;
			margin: -1.5rem 0 0 -1.5rem;
		}
		
		blockquote {
			display: block;
			margin: 0;
			padding: 0;
		}
		
		@include media-breakpoint-up(md) {
			margin: 2.5rem -3rem 2rem;
			padding: 1rem 1rem 1rem 14rem;
			
			.photo {
				left: 2rem;
				margin-top: -5rem;
				margin-left: 0;
				top: 50%;
				width: 10rem;
			}
		}
		
	}
	
	.note {
		padding-top: 0.5rem;
		
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
		}
	}
}

// toggle in header 
.awareness-toggle {
	min-width: 25%;
	
	img {
		width: 15vw;
		max-width: 8rem !important;
	}
}