.search-ui {
	background: $primary;
	padding: 1rem 0;
	position: relative;
	z-index: 100;
	
	@include media-breakpoint-up(md) {
		background: darken($blue, 10%);
		//display: none;
		height: 0;
		overflow: hidden;
		opacity: 0;
		padding: 0;
		transition: all ease-out 0.2s;
		
		.input-group > .form-control,
		.input-group > .input-group-prepend > .input-group-text,
		.input-group > .input-group-append > .input-group-text,
		.input-group > .input-group-prepend > .btn,
		.input-group > .input-group-append > .btn {
			padding: 4px 8px;
			@include font-size(0.75rem);
			line-height: 1.2;
			height: 1.5rem;
		}
		
		&.show {
			display: block;
			height: auto;
			padding: 0.5rem 0;
			opacity: 1;
			overflow: visible;
		}
	}
	
	.btn-clear {
		
		.icon {
			top: 0;
		}
		
		&:hover {
			color: $red !important;
		}
	}
	
	.btn-done {
		font-size: 0.75rem;
		padding: 0.125rem 0.5rem;
	}
}

.search-results {
	background: white;
	border-radius: $border-radius;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
	margin: 0;
	padding: 1rem 1rem 1rem 2rem;
	width: 100%;
	
	li {
		
		+ li {
			margin-top: 1rem;
		}
	}
	
	@include media-breakpoint-up(md) {
		position: absolute;
		top: 101%;
		left: 1rem;
		width: calc(100vw - 2rem);
		max-width: 1108px;
	}
}