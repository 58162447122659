.dosing-app {
	
	.btn-nav {
		background-color: rgba(256, 256, 256, 0.75);
		box-shadow: unset !important;
		color: $primary;
		text-align: left;
		
		&:hover {
			background-color: rgba(256, 256, 256, 0.9);
			color: $gray-800;
		}
		
		&.active {
			background-color: rgba(256, 256, 256, 1);
			color: $gray-800;
		}
	}
	
}

.dosing-app-type {
	align-items: center;
	display: flex;
	font-size: 1rem;
	line-height: 1.25;
	text-align: left;
	
	@include media-breakpoint-up(lg) {
		font-size: 1.25rem;
		line-height: 1.2;
	}
}

.dosing-app {
	
	.calculator {
		border: 1px solid $gray-200;
	}
	
	.calc-header {
		background: $cyan;
		padding: 1rem;
	}
	
	.calc-body {
		padding: 2rem;
	}
	
	.calc-footer {
		background: $gray-100;
		margin: 0 2rem 2rem;
		padding: 2rem;
	}
	
	.calc-form-group {
		//margin: 0 0.5rem;
		text-align: center;
		
		label,
		.sublabel {
			color: white;
			display: block;
			font-size: 80%;
			line-height: 1.125rem;
			margin: 0;
		}
	}
	
	.calc-input {
		border: $gray-100;
		border-radius: 0.25em;;
		display: block;
		font-size: 1.125rem;
		line-height: 1.5rem;
		margin: 0.5rem auto;
		padding: 0.5em;
		width: 5em;
	}
	
	.calc-radios {
		align-items: center;
		display: flex;
		justify-content: center;
		line-height: 1.125rem;
	}
	
	.btn-reset {
		border: unset;
		color: white;
		opacity: 0.5;
		padding: 0;
		text-decoration: none;
		transition: all 0.6s; 
		
		.icon {
			margin: 0.5rem auto;
		}
		
		&:hover,
		&:focus {
			opacity: 0.9;
		}
	} 
	
	.calc-result {
		font-size: 1.5rem;
	}
	
	.desired-level {
		background-color: rgba(256, 256, 256, 0.95);
		margin-top: 1rem;
		padding: 1rem;
		
		p {
			font-size: 80%;
			line-height: 1.125rem;
			margin: 0 0 0.25rem;
		}
	}
	
	.dropdown {
		z-index: 1;
	}
	
	.dropdown-options {
		background-color: white;
		border-radius: $border-radius-sm;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
		position: absolute;
		top: 100%;
		left: 0;
		text-align: left;
		width: 100%;
		
		.option {
			cursor: pointer;
			padding: 0.5rem 0.5rem 0.5rem 2rem;
			
			&:hover {
				background-color: scale-color($primary, $lightness: 90%);
			}
			
			&.active:before {
				color: $success;
				content: "✓";
				display: inline-block;
				margin-left: -1.5rem;
				width: 1.5rem;
			}
			
			&:first-child {
				border-top-left-radius: $border-radius-sm;
				border-top-right-radius: $border-radius-sm;
			}
			
			&:last-child {
				border-bottom-left-radius: $border-radius-sm;
				border-bottom-right-radius: $border-radius-sm;
			}
		}
	 }
}