.faq-nav {
	.icon {
		transition: all ease 0.2s;
		&.open {
			transform: rotate(90deg);
		}
	}
	
	.btn {
		&:focus,
		&:active {
			box-shadow: none !important;
		}
	}
}


.faq-item {
	padding: 1rem 0;
}

.btn-faq-top {
	background: lighten($gray-100, 5%);
	border: 1px solid transparent;
	border-radius: $border-radius-lg;
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.2);
	color: $primary;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.25rem;
	text-transform: uppercase;
	width: 6rem;
	height: 6rem;
	transition: all ease 0.3s;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 10%;
	
	
	&:hover {
		background: $primary-lightest;
		border-color: $gray-200;
		box-shadow: unset;
	}
	
	@include media-breakpoint-up(md) {
		left: auto;
		right: 2.5%;
		top: 50%;
		transform: translateY(-50%);
	}
	
	@include media-breakpoint-up(lg) {
		right: 5%;
	}
}