@charset "UTF-8";
/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #004b87;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #b32028;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #00aeef;
  --white: #fff;
  --gray: #465266;
  --gray-dark: #1c2533;
  --primary: #004b87;
  --secondary: #465266;
  --success: #28a745;
  --info: #00aeef;
  --warning: #ffc107;
  --danger: #b32028;
  --light: #f2f7fc;
  --dark: #2f3947;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 1, 4, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a1019;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #004b87;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #00213b;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #465266;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h2, .h2 {
  font-size: 1.625rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.2875rem + 0.45vw) ; } }

h3, .h3 {
  font-size: 1.375rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.2625rem + 0.15vw) ; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 1, 4, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #465266; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #9fa7b1;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 1, 4, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #465266; }

.container,
.container-fluid,
.container-xl {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1472px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #0a1019; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #9fa7b1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #9fa7b1; }
  .table tbody + tbody {
    border-top: 2px solid #9fa7b1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #9fa7b1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #9fa7b1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 1, 4, 0.05); }

.table-hover tbody tr:hover {
  color: #0a1019;
  background-color: rgba(0, 1, 4, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8cddd; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7aa1c1; }

.table-hover .table-primary:hover {
  background-color: #a7c1d5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a7c1d5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cbcfd4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9fa5af; }

.table-hover .table-secondary:hover {
  background-color: #bdc2c8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #bdc2c8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e8fb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ad5f7; }

.table-hover .table-info:hover {
  background-color: #a0e0fa; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a0e0fa; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #eac1c3; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #d78b8f; }

.table-hover .table-danger:hover {
  background-color: #e4aeb1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e4aeb1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f8fbfd; }

.table-hover .table-light:hover {
  background-color: #e7f3f9; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e7f3f9; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c8cb; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #93989f; }

.table-hover .table-dark:hover {
  background-color: #b8bbbf; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b8bbbf; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 1, 4, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #1c2533;
  border-color: #2a374c; }

.table .thead-light th {
  color: #2f3947;
  background-color: #c2c6cc;
  border-color: #9fa7b1; }

.table-dark {
  color: #fff;
  background-color: #1c2533; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #2a374c; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2f3947;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #808998;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 1, 4, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2f3947; }
  .form-control:focus {
    color: #2f3947;
    background-color: #fff;
    border-color: #0891ff;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
  .form-control::placeholder {
    color: #465266;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #c2c6cc;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #2f3947;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #0a1019;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.75rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #465266; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.5rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231c2533' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #b32028; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(179, 32, 40, 0.9);
  border-radius: 0.5rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #b32028;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23b32028' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b32028' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #b32028;
    box-shadow: 0 0 0 0.2rem rgba(179, 32, 40, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #b32028;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231c2533' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23b32028' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b32028' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #b32028;
    box-shadow: 0 0 0 0.2rem rgba(179, 32, 40, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #b32028; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #b32028; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #b32028; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d92d36;
  background-color: #d92d36; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(179, 32, 40, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b32028; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #b32028; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #b32028;
  box-shadow: 0 0 0 0.2rem rgba(179, 32, 40, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #0a1019;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #0a1019;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 75, 135, 0.25), inset 0 3px 5px rgba(0, 1, 4, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #004b87;
  border-color: #004b87;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #003661;
    border-color: #002f54; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #003661;
    border-color: #002f54;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(38, 102, 153, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #004b87;
    border-color: #004b87; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #002f54;
    border-color: #002847; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(38, 102, 153, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #465266;
  border-color: #465266;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #36404f;
    border-color: #313a48; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #36404f;
    border-color: #313a48;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(98, 108, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #465266;
    border-color: #465266; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #313a48;
    border-color: #2c3440; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(98, 108, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00aeef;
  border-color: #00aeef;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #0092c9;
    border-color: #0089bc; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #0092c9;
    border-color: #0089bc;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(38, 186, 241, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00aeef;
    border-color: #00aeef; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0089bc;
    border-color: #0080af; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(38, 186, 241, 0.5); }

.btn-warning {
  color: #1c2533;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-warning:hover {
    color: #1c2533;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #1c2533;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(221, 170, 14, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #1c2533;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #1c2533;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(221, 170, 14, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #b32028;
  border-color: #b32028;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #931a21;
    border-color: #88181e; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #931a21;
    border-color: #88181e;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(190, 65, 72, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #b32028;
    border-color: #b32028; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #88181e;
    border-color: #7d161c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(190, 65, 72, 0.5); }

.btn-light {
  color: #1c2533;
  background-color: #f2f7fc;
  border-color: #f2f7fc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-light:hover {
    color: #1c2533;
    background-color: #d3e4f5;
    border-color: #c9def2; }
  .btn-light:focus, .btn-light.focus {
    color: #1c2533;
    background-color: #d3e4f5;
    border-color: #c9def2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(210, 216, 222, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #1c2533;
    background-color: #f2f7fc;
    border-color: #f2f7fc; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #1c2533;
    background-color: #c9def2;
    border-color: #bed7f0; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(210, 216, 222, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #2f3947;
  border-color: #2f3947;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #202730;
    border-color: #1b2028; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #202730;
    border-color: #1b2028;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(78, 87, 99, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2f3947;
    border-color: #2f3947; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1b2028;
    border-color: #161a21; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(78, 87, 99, 0.5); }

.btn-outline-primary {
  color: #004b87;
  border-color: #004b87; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #004b87;
    border-color: #004b87; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 75, 135, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #004b87;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #004b87;
    border-color: #004b87; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(0, 75, 135, 0.5); }

.btn-outline-secondary {
  color: #465266;
  border-color: #465266; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #465266;
    border-color: #465266; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(70, 82, 102, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #465266;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #465266;
    border-color: #465266; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(70, 82, 102, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #00aeef;
  border-color: #00aeef; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00aeef;
    border-color: #00aeef; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 174, 239, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00aeef;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00aeef;
    border-color: #00aeef; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(0, 174, 239, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #1c2533;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1c2533;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #b32028;
  border-color: #b32028; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #b32028;
    border-color: #b32028; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(179, 32, 40, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #b32028;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #b32028;
    border-color: #b32028; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(179, 32, 40, 0.5); }

.btn-outline-light {
  color: #f2f7fc;
  border-color: #f2f7fc; }
  .btn-outline-light:hover {
    color: #1c2533;
    background-color: #f2f7fc;
    border-color: #f2f7fc; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 247, 252, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f2f7fc;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #1c2533;
    background-color: #f2f7fc;
    border-color: #f2f7fc; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(242, 247, 252, 0.5); }

.btn-outline-dark {
  color: #2f3947;
  border-color: #2f3947; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #2f3947;
    border-color: #2f3947; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 57, 71, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2f3947;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #2f3947;
    border-color: #2f3947; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 1, 4, 0.125), 0 0 0 0.2rem rgba(47, 57, 71, 0.5); }

.btn-link {
  font-weight: 400;
  color: #004b87;
  text-decoration: none; }
  .btn-link:hover {
    color: #00213b;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #465266;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.75rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #0a1019;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 1, 4, 0.15);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 1, 4, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #c2c6cc; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #0a1019;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #030407;
    text-decoration: none;
    background-color: #e6e8eb; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #004b87; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #465266;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #465266;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #0a1019; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2f3947;
  text-align: center;
  white-space: nowrap;
  background-color: #c2c6cc;
  border: 1px solid #808998;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.75rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #004b87;
    background-color: #004b87;
    box-shadow:; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 1px 1px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #0891ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #3ba8ff;
    border-color: #3ba8ff;
    box-shadow:; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #465266; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #c2c6cc; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #5f6c7f solid 1px;
    box-shadow: inset 0 1px 1px rgba(0, 1, 4, 0.075); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #004b87;
  background-color: #004b87;
  box-shadow:; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 75, 135, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 75, 135, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 75, 135, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #5f6c7f;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 75, 135, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2f3947;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%231c2533' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #808998;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(0, 1, 4, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #0891ff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 1, 4, 0.075), 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
    .custom-select:focus::-ms-value {
      color: #2f3947;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #465266;
    background-color: #c2c6cc; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2f3947; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #0891ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #c2c6cc; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2f3947;
  background-color: #fff;
  border: 1px solid #808998;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 1, 4, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #2f3947;
    content: "Browse";
    background-color: #c2c6cc;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 75, 135, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #004b87;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 1, 4, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #3ba8ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #9fa7b1;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 1, 4, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #004b87;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 1, 4, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #3ba8ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #9fa7b1;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 1, 4, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #004b87;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 1, 4, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #3ba8ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 1, 4, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #9fa7b1;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #9fa7b1;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #5f6c7f; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #5f6c7f; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #5f6c7f; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #465266;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #9fa7b1; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #c2c6cc #c2c6cc #9fa7b1; }
    .nav-tabs .nav-link.disabled {
      color: #465266;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #2f3947;
    background-color: #fff;
    border-color: #9fa7b1 #9fa7b1 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.5rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #004b87; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000104;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000104;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 1, 4, 0.2);
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 1, 4, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000104; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #9fa7b1;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #9fa7b1;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 1, 4, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #004b87 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #002f54 !important; }

.bg-secondary {
  background-color: #465266 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #313a48 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #00aeef !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0089bc !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #b32028 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #88181e !important; }

.bg-light {
  background-color: #f2f7fc !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c9def2 !important; }

.bg-dark {
  background-color: #2f3947 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1b2028 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #9fa7b1 !important; }

.border-top {
  border-top: 1px solid #9fa7b1 !important; }

.border-right {
  border-right: 1px solid #9fa7b1 !important; }

.border-bottom {
  border-bottom: 1px solid #9fa7b1 !important; }

.border-left {
  border-left: 1px solid #9fa7b1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #004b87 !important; }

.border-secondary {
  border-color: #465266 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #00aeef !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #b32028 !important; }

.border-light {
  border-color: #f2f7fc !important; }

.border-dark {
  border-color: #2f3947 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.25rem !important; }

.rounded {
  border-radius: 0.5rem !important; }

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important; }

.rounded-right {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important; }

.rounded-left {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important; }

.rounded-lg {
  border-radius: 0.75rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 1, 4, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 1, 4, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 1, 4, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #004b87 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #00213b !important; }

.text-secondary {
  color: #465266 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #272e39 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #00aeef !important; }

a.text-info:hover, a.text-info:focus {
  color: #0076a3 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #b32028 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #721419 !important; }

.text-light {
  color: #f2f7fc !important; }

a.text-light:hover, a.text-light:focus {
  color: #b4d1ee !important; }

.text-dark {
  color: #2f3947 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #111419 !important; }

.text-body {
  color: #0a1019 !important; }

.text-muted {
  color: #465266 !important; }

.text-black-50 {
  color: rgba(0, 1, 4, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #5f6c7f;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000104; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #9fa7b1 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #9fa7b1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #9fa7b1; } }

section {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.snap-start {
  scroll-snap-align: start; }

@media (min-width: 992px) {
  html {
    font-size: 1.125rem; } }

@media (min-width: 1200px) {
  html {
    font-size: 1.25rem; } }

h1 {
  color: #004b87;
  margin-bottom: 1em; }

h2 {
  color: #004b87;
  margin-bottom: 0.75em; }

.text-xxs {
  font-size: 11px; }

.text-xs {
  font-size: 0.75rem; }

.text-sm {
  font-size: 0.875rem; }

.text-base {
  font-size: 1rem; }

.text-lg {
  font-size: 1.125rem; }

.text-xl {
  font-size: 1.25rem; }

.text-2xl {
  font-size: 1.5rem; }

.text-3xl {
  font-size: 1.875rem; }

.text-4xl {
  font-size: 2.25rem; }

ul.bullet {
  list-style: none; }
  ul.bullet > li {
    margin-bottom: 0.5em; }
    ul.bullet > li:before {
      content: "•";
      display: inline-block;
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
      margin-left: -0.8em;
      vertical-align: top;
      width: 0.8em; }

ul.dash {
  list-style: none;
  padding-left: 1.5em; }
  ul.dash > li {
    margin: 0.25em 0; }
    ul.dash > li:before {
      content: "\2014";
      display: inline-block;
      margin-left: -1.5em;
      width: 1.5em; }

ul.item-mb-1 > li, ol.item-mb-1 > li {
  margin-bottom: 0.25rem; }

ul.item-mb-2 > li, ol.item-mb-2 > li {
  margin-bottom: 0.5rem; }

ul.item-mb-3 > li, ol.item-mb-3 > li {
  margin-bottom: 1rem; }

a.text-underline,
main a:not(.btn) {
  text-decoration: underline; }
  a.text-underline:hover,
  main a:not(.btn):hover {
    text-decoration: none !important; }

.font-style-normal {
  font-style: normal !important; }

@media (min-width: 576px) {
  .columns-sm-2 {
    columns: 2 !important; } }

@media (min-width: 768px) {
  .columns-md-3 {
    columns: 3 !important; } }

.hang-1 {
  display: inline-block;
  margin-left: calc(-1em - 3px);
  padding-right: 3px;
  width: calc(1em + 3px); }

sup.muted, sub.muted {
  font-size: 60%;
  font-weight: 400;
  margin-left: 0.125rem; }

.a-underline {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.15em; }
  .a-underline:hover {
    text-decoration: none; }

@media (min-width: 576px) {
  .text-sm-center {
    text-align: center; } }

@media (min-width: 768px) {
  .text-md-center {
    text-align: center; } }

@media (min-width: 992px) {
  .text-lg-center {
    text-align: center; } }

* {
  fill: currentColor; }

.icon {
  height: 1em;
  width: 1em;
  position: relative;
  top: 1px; }
  .icon.icon-lg {
    height: 1.5em;
    width: 1.5em; }
  .icon.icon-2x {
    height: 2em;
    width: 2em; }
  .icon.icon-3x {
    height: 3em;
    width: 3em; }
  .icon.icon-4x {
    height: 4em;
    width: 4em; }
  .icon.icon-5x {
    height: 5em;
    width: 5em; }
  .icon.icon-6x {
    height: 6em;
    width: 6em; }
  .icon.icon-8x {
    height: 8em;
    width: 8em; }

.icon-1x {
  width: 1em !important;
  height: 1em !important; }

.icon-lg {
  width: 1.5em !important;
  height: 1.5em !important; }

.icon-2x {
  width: 2em !important;
  height: 2em !important; }

.icon-3x {
  width: 3em !important;
  height: 3em !important; }

.icon-4x {
  width: 4em !important;
  height: 4em !important; }

.icon-5x {
  width: 5em !important;
  height: 5em !important; }

.icon-6x {
  width: 6em !important;
  height: 6em !important; }

.icon-8x {
  width: 8em !important;
  height: 8em !important; }

.icon-10x {
  width: 10em !important;
  height: 10em !important; }

.icon-12x {
  width: 12em !important;
  height: 12em !important; }

.icon-16x {
  width: 16em !important;
  height: 16em !important; }

.icon-20x {
  width: 20em !important;
  height: 20em !important; }

@media (min-width: 576px) {
  .icon-sm-1x {
    width: 1em !important;
    height: 1em !important; }
  .icon-sm-lg {
    width: 1.5em !important;
    height: 1.5em !important; }
  .icon-sm-2x {
    width: 2em !important;
    height: 2em !important; }
  .icon-sm-3x {
    width: 3em !important;
    height: 3em !important; }
  .icon-sm-4x {
    width: 4em !important;
    height: 4em !important; }
  .icon-sm-5x {
    width: 5em !important;
    height: 5em !important; }
  .icon-sm-6x {
    width: 6em !important;
    height: 6em !important; }
  .icon-sm-8x {
    width: 8em !important;
    height: 8em !important; }
  .icon-sm-10x {
    width: 10em !important;
    height: 10em !important; }
  .icon-sm-12x {
    width: 12em !important;
    height: 12em !important; }
  .icon-sm-16x {
    width: 16em !important;
    height: 16em !important; }
  .icon-sm-20x {
    width: 20em !important;
    height: 20em !important; } }

@media (min-width: 768px) {
  .icon-md-1x {
    width: 1em !important;
    height: 1em !important; }
  .icon-md-lg {
    width: 1.5em !important;
    height: 1.5em !important; }
  .icon-md-2x {
    width: 2em !important;
    height: 2em !important; }
  .icon-md-3x {
    width: 3em !important;
    height: 3em !important; }
  .icon-md-4x {
    width: 4em !important;
    height: 4em !important; }
  .icon-md-5x {
    width: 5em !important;
    height: 5em !important; }
  .icon-md-6x {
    width: 6em !important;
    height: 6em !important; }
  .icon-md-8x {
    width: 8em !important;
    height: 8em !important; }
  .icon-md-10x {
    width: 10em !important;
    height: 10em !important; }
  .icon-md-12x {
    width: 12em !important;
    height: 12em !important; }
  .icon-md-16x {
    width: 16em !important;
    height: 16em !important; }
  .icon-md-20x {
    width: 20em !important;
    height: 20em !important; } }

@media (min-width: 992px) {
  .icon-lg-1x {
    width: 1em !important;
    height: 1em !important; }
  .icon-lg-lg {
    width: 1.5em !important;
    height: 1.5em !important; }
  .icon-lg-2x {
    width: 2em !important;
    height: 2em !important; }
  .icon-lg-3x {
    width: 3em !important;
    height: 3em !important; }
  .icon-lg-4x {
    width: 4em !important;
    height: 4em !important; }
  .icon-lg-5x {
    width: 5em !important;
    height: 5em !important; }
  .icon-lg-6x {
    width: 6em !important;
    height: 6em !important; }
  .icon-lg-8x {
    width: 8em !important;
    height: 8em !important; }
  .icon-lg-10x {
    width: 10em !important;
    height: 10em !important; }
  .icon-lg-12x {
    width: 12em !important;
    height: 12em !important; }
  .icon-lg-16x {
    width: 16em !important;
    height: 16em !important; }
  .icon-lg-20x {
    width: 20em !important;
    height: 20em !important; } }

@media (min-width: 1200px) {
  .icon-xl-1x {
    width: 1em !important;
    height: 1em !important; }
  .icon-xl-lg {
    width: 1.5em !important;
    height: 1.5em !important; }
  .icon-xl-2x {
    width: 2em !important;
    height: 2em !important; }
  .icon-xl-3x {
    width: 3em !important;
    height: 3em !important; }
  .icon-xl-4x {
    width: 4em !important;
    height: 4em !important; }
  .icon-xl-5x {
    width: 5em !important;
    height: 5em !important; }
  .icon-xl-6x {
    width: 6em !important;
    height: 6em !important; }
  .icon-xl-8x {
    width: 8em !important;
    height: 8em !important; }
  .icon-xl-10x {
    width: 10em !important;
    height: 10em !important; }
  .icon-xl-12x {
    width: 12em !important;
    height: 12em !important; }
  .icon-xl-16x {
    width: 16em !important;
    height: 16em !important; }
  .icon-xl-20x {
    width: 20em !important;
    height: 20em !important; } }

.site-header {
  position: sticky;
  top: 0;
  z-index: 1000; }

.header-nav {
  height: calc(100dvh - 4rem);
  overflow: auto; }
  @media (min-width: 768px) {
    .header-nav {
      height: auto;
      overflow: visible; } }

html {
  scroll-snap-type: y proximity;
  scroll-padding-top: 4rem;
  overflow-y: scroll; }
  @media (min-width: 768px) {
    html {
      scroll-padding-top: 10.5em; } }
  @media (min-width: 992px) {
    html {
      scroll-padding-top: 9.55555556em; } }
  @media (min-width: 1200px) {
    html {
      scroll-padding-top: 9.2em; } }

.mobile-header {
  background: #f2f7fc;
  color: white;
  line-height: 1; }
  .mobile-header .mobile-header-logo {
    font-size: 20px;
    line-height: 22px;
    height: 4rem;
    margin: 0;
    padding: 0.5rem 0 0 1rem; }
    .mobile-header .mobile-header-logo a {
      text-decoration: none; }
  .mobile-header .btn {
    line-height: 1;
    text-decoration: none; }

.header-logo-wrapper {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 30%;
  position: relative; }

.header-logo-addon {
  align-self: stretch;
  border-left: 1px solid #004b87;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.5rem; }

.nav-main {
  background-color: #004b87; }
  @media (min-width: 768px) {
    .nav-main {
      font-size: 0.8125rem; } }
  @media (min-width: 992px) {
    .nav-main {
      font-size: 0.88888889rem; } }
  @media (min-width: 1200px) {
    .nav-main {
      font-size: 0.9rem; } }
  .nav-main .nav-link {
    color: white;
    padding: 0.5em 1em; }
    .nav-main .nav-link:hover, .nav-main .nav-link:focus {
      background: #003d6e; }
    .nav-main .nav-link[aria-current="page"] {
      background: #002f54; }
    @media (min-width: 768px) {
      .nav-main .nav-link {
        padding: 0.5em; } }
    @media (min-width: 992px) {
      .nav-main .nav-link {
        padding: 0.5em 1em; } }
  .hcp .nav-main {
    background-color: #002f54; }
    .hcp .nav-main .nav-link:hover, .hcp .nav-main .nav-link:focus {
      background: #003d6e; }
    .hcp .nav-main .nav-link[aria-current="page"] {
      background: #00aeef; }
  .nav-main .nav {
    display: block; }
    @media (min-width: 768px) {
      .nav-main .nav {
        display: flex;
        align-items: stretch;
        flex-wrap: nowrap;
        justify-content: stretch;
        margin-left: -1rem;
        margin-right: -1rem;
        min-height: 60px; }
        .nav-main .nav .nav-item {
          align-items: stretch;
          display: flex;
          flex: 1 0 0%; }
          .nav-main .nav .nav-item.home, .nav-main .nav .nav-item.nav-search {
            flex: 0 1 4em; }
          .nav-main .nav .nav-item:hover .hover-menu {
            display: block; }
        .nav-main .nav .nav-link {
          align-items: center;
          display: flex;
          flex: 1;
          justify-content: center;
          text-align: center; }
          .nav-main .nav .nav-link .nav-label {
            display: block; }
          .nav-main .nav .nav-link.nav-group .triangle {
            display: inline-block;
            margin-left: .255em;
            vertical-align: middle;
            border-top: .3em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent; } }
  .nav-main .hover-menu {
    display: block; }
    .nav-main .hover-menu .menu-item {
      display: block;
      color: white;
      padding: 0.5em 1em 0.5em 2.5em; }
      .nav-main .hover-menu .menu-item[aria-current="page"] {
        background: #002f54; }
    @media (min-width: 768px) {
      .nav-main .hover-menu {
        position: absolute;
        top: 98%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 100%;
        padding: .5rem 0;
        margin: 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: .25rem; }
        .nav-main .hover-menu .menu-item {
          width: 100%;
          padding: .25rem 1.5rem;
          clear: both;
          font-weight: 400;
          color: #004b87;
          text-align: inherit;
          white-space: nowrap;
          background-color: transparent;
          border: 0; }
          .nav-main .hover-menu .menu-item[aria-current="page"] {
            background: transparent;
            color: #000;
            cursor: default;
            font-weight: bold; }
            .nav-main .hover-menu .menu-item[aria-current="page"]:hover {
              text-decoration: none; } }
  @media (min-width: 768px) {
    .nav-main .nav-item:last-child .hover-menu {
      left: auto;
      right: 0; } }

.branding {
  background-color: white; }

.nav-secondary {
  background-color: #004b87;
  text-align: left; }
  @media (min-width: 768px) {
    .nav-secondary {
      background-color: transparent;
      font-size: 0.75rem; }
      .nav-secondary .nav {
        justify-content: flex-end; } }
  @media (max-width: 767.98px) {
    .nav-secondary .nav-link {
      color: white;
      padding: 0.5em 1em; }
      .nav-secondary .nav-link:hover, .nav-secondary .nav-link:focus {
        background: #0a1019; }
      .nav-secondary .nav-link[data-state="active"], .nav-secondary .nav-link[aria-current="page"] {
        background: #0a1019; } }
  @media (min-width: 768px) {
    .nav-secondary .nav-link {
      margin: 0 0 0.25rem 0;
      padding: 0 0.5rem;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px; }
      .nav-secondary .nav-link[data-state="active"], .nav-secondary .nav-link[aria-current="page"] {
        color: black; }
      .nav-secondary .nav-link + .nav-link {
        border-left: 1px solid #c2c6cc; }
      .nav-secondary .nav-link:hover {
        text-decoration-color: #b32028;
        text-decoration-thickness: 2px; } }
  @media (min-width: 992px) {
    .nav-secondary .nav-link {
      margin: 0 0 0.5rem 0;
      padding: 0 0.75rem; } }

.header-tools {
  width: 100%; }
  .header-tools a,
  .header-tools button {
    font-weight: 500; }
  @media (min-width: 768px) {
    .header-tools {
      line-height: 1.1;
      text-align: right; }
      .header-tools a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px; }
        .header-tools a:hover {
          text-decoration-color: #b32028;
          text-decoration-thickness: 2px; } }
  @media (min-width: 992px) {
    .header-tools {
      line-height: 1.2;
      text-align: right; }
      .header-tools a,
      .header-tools button {
        font-size: 1.125em; } }
  .header-tools .share button {
    color: #004b87; }
    .header-tools .share button span {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-thickness: 1px; }
    .header-tools .share button:hover {
      color: #00213b; }
      .header-tools .share button:hover span {
        text-decoration-color: #b32028;
        text-decoration-thickness: 2px; }
  .header-tools .share .icon {
    font-size: 1rem;
    margin-left: 0.25rem;
    top: -2px; }
  @media (min-width: 768px) {
    .header-tools .share {
      border-left: 1px solid #c2c6cc; } }

.copy-status {
  transition: opacity ease-in-out 0.5s; }

.search-ui {
  background: #004b87;
  padding: 1rem 0;
  position: relative;
  z-index: 100; }
  @media (min-width: 768px) {
    .search-ui {
      background: #002f54;
      height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      transition: all ease-out 0.2s; }
      .search-ui .input-group > .form-control,
      .search-ui .input-group > .input-group-prepend > .input-group-text,
      .search-ui .input-group > .input-group-append > .input-group-text,
      .search-ui .input-group > .input-group-prepend > .btn,
      .search-ui .input-group > .input-group-append > .btn {
        padding: 4px 8px;
        font-size: 0.75rem;
        line-height: 1.2;
        height: 1.5rem; }
      .search-ui.show {
        display: block;
        height: auto;
        padding: 0.5rem 0;
        opacity: 1;
        overflow: visible; } }
  .search-ui .btn-clear .icon {
    top: 0; }
  .search-ui .btn-clear:hover {
    color: #b32028 !important; }
  .search-ui .btn-done {
    font-size: 0.75rem;
    padding: 0.125rem 0.5rem; }

.search-results {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 1rem 1rem 1rem 2rem;
  width: 100%; }
  .search-results li + li {
    margin-top: 1rem; }
  @media (min-width: 768px) {
    .search-results {
      position: absolute;
      top: 101%;
      left: 1rem;
      width: calc(100vw - 2rem);
      max-width: 1108px; } }

.site-footer {
  padding-bottom: 90px; }
  .isi-page .site-footer {
    padding-bottom: 0; }

.isi-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99; }

.btn-isi {
  background-color: #004b87;
  border-top: 1px solid white;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.2);
  color: white;
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5rem;
  text-align: center;
  transition: all 0.5s ease-out;
  width: 100%; }
  .btn-isi:hover {
    background-color: #003663;
    background-position: center 50%;
    color: white;
    text-decoration: none; }

.home-hero {
  padding-top: 0;
  position: relative; }
  .home-hero:after {
    content: "";
    display: block;
    background: url(../img/swoosh.svg) repeat-x 58% bottom;
    background-size: 1500px;
    padding-bottom: 11.666668vw;
    position: absolute;
    left: 0;
    bottom: 18px;
    width: 100%; }
    @media screen and (min-width: 600px) {
      .home-hero:after {
        background-position: calc(62% + 17vw) bottom;
        background-size: 2400px;
        bottom: 22px;
        padding-bottom: 70px; } }
    @media screen and (min-width: 1472px) {
      .home-hero:after {
        background-position: calc(50% + 150px) bottom; } }

.home-hero-container {
  background: url(../img/hiker-2022-sm.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: 1472px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .home-hero-container {
      background: url(../img/hiker-2022.jpg) no-repeat;
      background-size: cover;
      background-position: right center; } }

.home-hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 62vw;
  margin: 0;
  padding-left: 52%;
  padding-right: 1rem; }
  .home-hero-content h1 {
    margin: 0 0 0.5rem 0;
    width: 100%; }
    .home-hero-content h1 span {
      background: url(../img/replace-exactly.svg) no-repeat left top;
      background-size: 100%;
      display: block;
      padding-bottom: 12.5vw;
      width: 42vw; }
  .home-hero-content h2 {
    font-size: 3.466667vw;
    margin: 0; }
  .home-hero-content .find-fast {
    font-size: 0.875rem;
    margin: 0 1rem 1rem;
    width: 100%;
    z-index: 1; }
  .home-hero-content .find-fast-panel {
    box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 1, 4, 0.15) !important; }
  @media screen and (min-width: 600px) {
    .home-hero-content {
      min-height: 60vw;
      padding: 0 1rem 0 56%;
      width: 100%; }
      .home-hero-content h1 span {
        padding-bottom: 26.037736%;
        position: static;
        top: auto;
        transform: unset;
        width: 100%; }
      .home-hero-content h2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.5rem;
        width: 100%; }
      .home-hero-content .find-fast {
        display: block;
        height: 33.263876%;
        margin-left: 0;
        margin-right: 0; } }
  @media (min-width: 768px) {
    .home-hero-content {
      padding: 0 1rem 0 61%; }
      .home-hero-content h2 {
        font-size: 1.953125vw; }
      .home-hero-content .find-fast {
        font-size: 1rem;
        padding-bottom: 2.5rem; }
      .home-hero-content .find-fast-panel {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%; } }
  @media screen and (min-width: 1472px) {
    .home-hero-content {
      height: 812px;
      min-height: 812px;
      padding: 0 0 0 900px; }
      .home-hero-content h2 {
        font-size: 1.25rem; } }

.home-cta {
  padding-right: 1rem;
  padding-bottom: 1rem; }
  @media screen and (min-width: 600px) {
    .home-cta {
      display: flex;
      flex-direction: column;
      height: 14%;
      justify-content: center;
      padding: 0; } }

.home-cta-btn {
  background-color: #00aeef;
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
  color: white;
  display: block;
  font-size: calc(1.2875rem + .45vw);
  font-weight: 700;
  line-height: 1.25;
  padding: 3.25vw;
  text-align: center;
  text-decoration: none !important;
  transition: all ease-in 0.2s; }
  .home-cta-btn:hover {
    background-color: #004b87;
    color: white; }
  @media screen and (min-width: 600px) {
    .home-cta-btn {
      font-size: 1.953125vw;
      padding: 3.472222% 6.944444% 3.472222% 3.472222%; } }
  @media (min-width: 1200px) {
    .home-cta-btn {
      font-size: 1.25rem; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .home-cta-btn {
      background: url("/img/home-cta-right.svg") no-repeat left top;
      background-size: 100% 100%; } }

.rounded-bottom-lg {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important; }

.border-left-dotted {
  border-left-style: dotted !important; }

.border-top-dotted {
  border-top-style: dotted !important;
  border-top-width: 2px !important; }

.border-2 {
  border-width: 2px !important; }

.border-8 {
  border-width: 8px !important; }

.border-10 {
  border-width: 10px !important; }

.img-round {
  border-radius: 50%; }

.z10 {
  z-index: 10; }

.border-y-6x {
  border-top: 6px solid black;
  border-bottom: 6px solid black; }

.inline-rule-middle {
  display: flex;
  align-items: flex-start;
  text-align: left; }
  .inline-rule-middle:after {
    background-color: currentcolor;
    content: "";
    flex-grow: 1;
    height: 1px;
    min-width: 1rem;
    margin: auto auto auto 1rem; }

.video-btn {
  display: block;
  position: relative; }
  .video-btn .icon-play {
    color: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3rem 0 0 -3rem;
    width: 6rem;
    height: 6rem;
    filter: drop-shadow(2px 2px 8px #465266);
    transition: all ease-in 0.2s;
    opacity: 0.9; }
    @media (min-width: 768px) {
      .video-btn .icon-play {
        margin: -4rem 0 0 -4rem;
        width: 8rem;
        height: 8rem; } }
    @media (min-width: 768px) {
      .video-btn .icon-play.icon-play-sm {
        margin: -3rem 0 0 -3rem;
        width: 6rem;
        height: 6rem; } }
  .video-btn:hover .icon-play {
    transform: scale(1.2);
    opacity: 1; }

.bg-md {
  background-color: #d2dbe7; }

.bg-primary-light {
  background: #D5DDEE; }

.bg-primary-light-alt {
  background: #adcde6; }

.bg-primary-lighter {
  background: #E2EBF5; }

.bg-primary-lightest {
  background: #f2f7fc; }

.bg-primary-bright {
  background: #CDE9F9; }

.bg-primary-brighter {
  background: #e8f6ff; }

.bg-primary-dark {
  background: #003d6e; }

.modal-hd {
  max-width: 1920px; }

.opacity-0 {
  opacity: 0; }

.max-w-150 {
  max-width: 150px;
  height: auto; }

.brackets-magenta {
  border: 4px solid magenta; }
  .brackets-magenta .brackets-content {
    background-color: white;
    margin: -5px 2rem; }

.symptoms-list li {
  line-height: 1.25; }

@media (min-width: 768px) {
  .doc-symptoms {
    padding-right: 20%; } }

.symptom-checker img {
  max-width: 13rem; }

@media (min-width: 768px) {
  .symptom-checker {
    margin-top: -7rem; } }

@media (min-width: 768px) {
  .symptom-checker {
    margin-top: -8.75rem; } }

@media (min-width: 992px) {
  .how-treated-bullet-1 {
    letter-spacing: -1px; } }

.test-equation .icon {
  width: 5rem;
  height: 5rem; }
  @media (min-width: 992px) {
    .test-equation .icon {
      width: 3rem;
      height: 3rem; } }
  @media (min-width: 1200px) {
    .test-equation .icon {
      width: 5rem;
      height: 5rem; } }

.how-diagram {
  background: url("/img/coagadex-diagram-vertical_r1.svg") no-repeat left top;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  font-size: 3.75vw;
  font-weight: bold;
  line-height: 1.25;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%; }
  .how-diagram .step-1,
  .how-diagram .step-2,
  .how-diagram .step-3,
  .how-diagram .step-4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 54%; }
  .how-diagram .step-1 {
    height: calc( (100vw - 2rem) * 0.49705764); }
  .how-diagram .step-2 {
    height: calc( (100vw - 2rem) * 0.578125); }
  .how-diagram .step-3 {
    height: calc( (100vw - 2rem) * 0.43142361); }
  .how-diagram .step-4 {
    align-items: flex-start;
    padding-top: calc( (100vw - 2rem) * 0.63194444);
    padding-left: 0; }
    .how-diagram .step-4 div:first-child {
      padding-right: 1rem; }
      .how-diagram .step-4 div:first-child .step-4-heading {
        letter-spacing: -1px; }
    .how-diagram .step-4 div:last-child {
      padding-left: 1rem; }
  .how-diagram ul {
    list-style-type: disc;
    margin-left: 0;
    padding-left: 1.5em; }
  .how-diagram .step-4-heading {
    border-bottom: 1px solid #1c2533;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em; }
  @media (min-width: 576px) {
    .how-diagram {
      font-size: 21px;
      width: 33.75rem; }
      .how-diagram .step-1 {
        height: 268.411125px; }
      .how-diagram .step-2 {
        height: 312.1875px; }
      .how-diagram .step-3 {
        height: 232.968749px; }
      .how-diagram .step-4 {
        align-items: flex-start;
        padding-top: 341.249997px;
        padding-left: 0; }
        .how-diagram .step-4 div:first-child {
          padding-right: 1rem; }
          .how-diagram .step-4 div:first-child .step-4-heading {
            letter-spacing: -1px; }
        .how-diagram .step-4 div:last-child {
          padding-left: 1rem; } }
  @media (min-width: 768px) {
    .how-diagram {
      background: url("/img/coagadex-diagram-horizontal_r1.svg") no-repeat left top;
      background-size: 100%;
      flex-direction: row;
      font-size: 1.5625vw;
      margin-top: -3rem;
      min-height: calc( (100vw - 5rem) * 0.40789474);
      width: 100%; }
      .how-diagram .step-1,
      .how-diagram .step-2,
      .how-diagram .step-3,
      .how-diagram .step-4 {
        flex-direction: column;
        align-items: stretch;
        height: auto; }
      .how-diagram .step-1,
      .how-diagram .step-2,
      .how-diagram .step-3 {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 29.333%;
        text-align: center; }
      .how-diagram .step-1 {
        width: 18.75%; }
      .how-diagram .step-2 {
        width: 21.5%; }
      .how-diagram .step-3 {
        width: 16.25%; }
      .how-diagram .step-4 {
        padding: 0 0 0 1rem;
        width: 43.5%; }
        .how-diagram .step-4 div:first-child {
          padding-left: 0;
          padding-right: 0;
          min-height: 17.125vw; }
          .how-diagram .step-4 div:first-child .step-4-heading {
            letter-spacing: 0;
            margin-top: -2.25em; }
        .how-diagram .step-4 div:last-child {
          padding-left: 0; }
      .how-diagram ul {
        padding-left: 57.5%; } }
  @media (min-width: 992px) {
    .how-diagram {
      margin-top: -5rem; } }
  @media (min-width: 1200px) {
    .how-diagram {
      font-size: 1rem;
      min-height: 560px; } }
  @media (min-width: 1472px) {
    .how-diagram .step-4 div:first-child {
      min-height: 252px; } }

.rule-blue-fade {
  display: block;
  width: 100%;
  height: 3px; }

table.border-white th, table.border-white td {
  border: 1px solid #ffffff; }

table.border-right-none th:last-child, table.border-right-none td:last-child {
  border-right: none; }

.border-bottom-left-radius {
  border-bottom-left-radius: 10px; }

caption {
  caption-side: top;
  font-weight: bold; }

.hcp-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%; }
  .hcp-table th {
    font-weight: 700; }
  .hcp-table thead th {
    vertical-align: bottom; }
  .hcp-table th,
  .hcp-table td {
    border: 1px solid #9fa7b1;
    font-size: 11px;
    line-height: 1.25;
    padding: 0.5em; }
    .hcp-table th.border-top-primary,
    .hcp-table td.border-top-primary {
      border-top-color: #004b87; }
    .hcp-table th.border-right-primary,
    .hcp-table td.border-right-primary {
      border-right-color: #004b87; }
    .hcp-table th.border-bottom-primary,
    .hcp-table td.border-bottom-primary {
      border-bottom-color: #004b87; }
    .hcp-table th.border-left-primary,
    .hcp-table td.border-left-primary {
      border-left-color: #004b87; }
    @media (min-width: 576px) {
      .hcp-table th,
      .hcp-table td {
        font-size: 14px; } }
    @media (min-width: 768px) {
      .hcp-table th,
      .hcp-table td {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .hcp-table th,
      .hcp-table td {
        font-size: 18px; } }
  .hcp-table.bpl-table-striped tbody td {
    background: #f2f7fc; }
  .hcp-table.bpl-table-striped tbody > tr:nth-child(odd) td {
    background: #E2EBF5; }
  .hcp-table .rounded-bottom-left {
    border-bottom-left-radius: 10px; }

@media (min-width: 576px) {
  .why-coagadex-tables .col-age {
    width: 18%; }
  .why-coagadex-tables .col-dose {
    width: 27%; } }

.inactivation-steps {
  align-items: center;
  flex-direction: column;
  width: 80%;
  max-width: 240px; }
  .inactivation-steps .step {
    text-align: left; }
    .inactivation-steps .step:not(.step-4) figcaption {
      padding-left: calc(1.25em + 4px); }
  .inactivation-steps .step-number {
    display: inline-block;
    margin-left: calc(-1.25em - 4px);
    width: 1.25em; }
  .inactivation-steps .arrow {
    margin: 0 auto;
    transform: rotate(90deg);
    width: 50px; }
  @media (min-width: 768px) {
    .inactivation-steps {
      align-items: flex-start;
      flex-direction: row;
      width: 82.5%;
      max-width: unset; }
      .inactivation-steps .step {
        width: 17.5vw; }
      .inactivation-steps .arrow {
        align-items: center;
        display: flex;
        height: 17.5vw;
        transform: none;
        width: 4.587156vw; } }
  @media (min-width: 1200px) {
    .inactivation-steps {
      width: 900px; }
      .inactivation-steps .step {
        width: 192px; }
      .inactivation-steps .arrow {
        height: 192px;
        width: 50px; } }

.dosing-app .btn-nav {
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: unset !important;
  color: #004b87;
  text-align: left; }
  .dosing-app .btn-nav:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: #1c2533; }
  .dosing-app .btn-nav.active {
    background-color: white;
    color: #1c2533; }

.dosing-app-type {
  align-items: center;
  display: flex;
  font-size: 1rem;
  line-height: 1.25;
  text-align: left; }
  @media (min-width: 992px) {
    .dosing-app-type {
      font-size: 1.25rem;
      line-height: 1.2; } }

.dosing-app .calculator {
  border: 1px solid #c2c6cc; }

.dosing-app .calc-header {
  background: #00aeef;
  padding: 1rem; }

.dosing-app .calc-body {
  padding: 2rem; }

.dosing-app .calc-footer {
  background: #e6e8eb;
  margin: 0 2rem 2rem;
  padding: 2rem; }

.dosing-app .calc-form-group {
  text-align: center; }
  .dosing-app .calc-form-group label,
  .dosing-app .calc-form-group .sublabel {
    color: white;
    display: block;
    font-size: 80%;
    line-height: 1.125rem;
    margin: 0; }

.dosing-app .calc-input {
  border: #e6e8eb;
  border-radius: 0.25em;
  display: block;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0.5rem auto;
  padding: 0.5em;
  width: 5em; }

.dosing-app .calc-radios {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1.125rem; }

.dosing-app .btn-reset {
  border: unset;
  color: white;
  opacity: 0.5;
  padding: 0;
  text-decoration: none;
  transition: all 0.6s; }
  .dosing-app .btn-reset .icon {
    margin: 0.5rem auto; }
  .dosing-app .btn-reset:hover, .dosing-app .btn-reset:focus {
    opacity: 0.9; }

.dosing-app .calc-result {
  font-size: 1.5rem; }

.dosing-app .desired-level {
  background-color: rgba(255, 255, 255, 0.95);
  margin-top: 1rem;
  padding: 1rem; }
  .dosing-app .desired-level p {
    font-size: 80%;
    line-height: 1.125rem;
    margin: 0 0 0.25rem; }

.dosing-app .dropdown {
  z-index: 1; }

.dosing-app .dropdown-options {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 100%;
  left: 0;
  text-align: left;
  width: 100%; }
  .dosing-app .dropdown-options .option {
    cursor: pointer;
    padding: 0.5rem 0.5rem 0.5rem 2rem; }
    .dosing-app .dropdown-options .option:hover {
      background-color: #daeeff; }
    .dosing-app .dropdown-options .option.active:before {
      color: #28a745;
      content: "✓";
      display: inline-block;
      margin-left: -1.5rem;
      width: 1.5rem; }
    .dosing-app .dropdown-options .option:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .dosing-app .dropdown-options .option:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem; }

.clinical-results-tabs .tab-content,
.clinical-results-footer .tab-content {
  align-items: center;
  border-radius: 0.5rem;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1rem;
  text-align: center; }
  .clinical-results-tabs .tab-content .icon,
  .clinical-results-footer .tab-content .icon {
    width: 4em;
    height: 4em; }
  @media (min-width: 992px) {
    .clinical-results-tabs .tab-content,
    .clinical-results-footer .tab-content {
      font-size: inherit; }
      .clinical-results-tabs .tab-content .icon,
      .clinical-results-footer .tab-content .icon {
        width: 6em;
        height: 6em; } }

.clinical-results-tabs .tab-heading,
.clinical-results-footer .tab-heading {
  font-size: 1.375rem; }

.clinical-results-tabs a,
.clinical-results-footer a {
  background: #f4f5f6;
  border: 1px solid #D5DDEE;
  border-radius: 0.5rem;
  display: block;
  flex: 1;
  padding: 1px;
  transition: all 0.5s ease; }
  .clinical-results-tabs a:hover,
  .clinical-results-footer a:hover {
    background: white;
    border-color: #004b87; }

.clinical-results-tabs .active-tab,
.clinical-results-footer .active-tab {
  border: 2px solid #004b87;
  border-radius: 0.5rem;
  flex: 1; }
  .clinical-results-tabs .active-tab .tab-heading,
  .clinical-results-footer .active-tab .tab-heading {
    color: black; }

.clinical-results-footer .tab-content .icon {
  width: 3em;
  height: 3em; }

.hcp-prophy .efficacy-graphic {
  margin-top: -1rem; }
  @media (min-width: 768px) {
    .hcp-prophy .efficacy-graphic {
      margin-top: -10.6%; } }

.faq-nav .icon {
  transition: all ease 0.2s; }
  .faq-nav .icon.open {
    transform: rotate(90deg); }

.faq-nav .btn:focus, .faq-nav .btn:active {
  box-shadow: none !important; }

.faq-item {
  padding: 1rem 0; }

.btn-faq-top {
  background: #f4f5f6;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  color: #004b87;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  text-transform: uppercase;
  width: 6rem;
  height: 6rem;
  transition: all ease 0.3s;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%; }
  .btn-faq-top:hover {
    background: #f2f7fc;
    border-color: #c2c6cc;
    box-shadow: unset; }
  @media (min-width: 768px) {
    .btn-faq-top {
      left: auto;
      right: 2.5%;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 992px) {
    .btn-faq-top {
      right: 5%; } }

.awareness-modal .drop-shadow {
  filter: drop-shadow(3px 4px 6px #2f3947); }

.awareness-modal .pull-up {
  margin-top: -33%; }

.awareness-modal .date-graphic {
  margin-top: -1rem;
  shape-outside: circle(50%); }

.awareness-modal .info {
  padding-top: 36%; }

.awareness-modal .btn-close {
  position: absolute;
  top: -0.25em;
  right: -0.625em; }
  @media (min-width: 576px) {
    .awareness-modal .btn-close {
      top: -1.5em;
      right: -1.5em; } }

.awareness-modal .patient-photo {
  border-top: 4px solid #004b87;
  border-right: 2px solid #004b87;
  border-bottom: 4px solid #004b87;
  border-left: 2px solid #004b87; }
  .awareness-modal .patient-photo:first-child {
    border-left-width: 4px; }
  .awareness-modal .patient-photo:last-child {
    border-right-width: 4px; }

.awareness-modal .john {
  background-color: #004b87;
  margin: 4rem -1rem 1.5rem;
  padding: 3.5rem 1rem 1rem;
  position: relative; }
  .awareness-modal .john .photo {
    position: absolute;
    top: -3rem;
    width: 6rem;
    left: 50%;
    margin-left: -3rem; }
  .awareness-modal .john .icon-play {
    width: 3rem;
    height: 3rem;
    margin: -1.5rem 0 0 -1.5rem; }
  .awareness-modal .john blockquote {
    display: block;
    margin: 0;
    padding: 0; }
  @media (min-width: 768px) {
    .awareness-modal .john {
      margin: 2.5rem -3rem 2rem;
      padding: 1rem 1rem 1rem 14rem; }
      .awareness-modal .john .photo {
        left: 2rem;
        margin-top: -5rem;
        margin-left: 0;
        top: 50%;
        width: 10rem; } }

.awareness-modal .note {
  padding-top: 0.5rem; }
  @media (min-width: 768px) {
    .awareness-modal .note {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%; } }

.awareness-toggle {
  min-width: 25%; }
  .awareness-toggle img {
    width: 15vw;
    max-width: 8rem !important; }

.enroll {
  background: url(../img/hiker-2022-sm.jpg) no-repeat center center #004b87;
  background-size: cover;
  min-height: 70vw; }
  .enroll .hiker {
    padding: 2.5vw 0 0 2.5vw; }
  .enroll h1 {
    font-size: 5vw;
    line-height: 1.1;
    text-shadow: 1px 1px 0 #004b87; }
  .enroll p {
    font-size: 4.0625vw;
    line-height: 1.333334; }
  @media (min-width: 768px) {
    .enroll {
      background: url(../img/hiker-2022.jpg) no-repeat right center #004b87;
      background-size: cover; }
      .enroll p {
        font-size: 3.90625vw; } }
  @media (min-width: 1200px) {
    .enroll {
      min-height: 58vw; }
      .enroll .hiker {
        padding: 1.5rem 0 0 1.5rem; }
      .enroll h1 {
        font-size: 2.5rem; }
      .enroll p {
        font-size: 2rem; } }
  @media screen and (min-width: 1472px) {
    .enroll {
      min-height: 800px; } }

@media (min-width: 1200px) {
  .patient-intro-header {
    font-size: 0.875rem; } }

.patient-intro-link {
  background: #00aeef;
  display: block;
  padding: 88% 0 1rem 0;
  text-align: center; }
  .patient-intro-link.olivia {
    background: #00aeef url(../img/patients/2022/olivia.jpg) no-repeat center center;
    background-size: cover; }
  .patient-intro-link.carly {
    background: #00aeef url(../img/patients/2022/carly.jpg) no-repeat center center;
    background-size: cover; }
  .patient-intro-link.leah {
    background: #00aeef url(../img/patients/2022/leah.jpg) no-repeat center center;
    background-size: cover; }
  .patient-intro-link.john {
    background: #00aeef url(../img/patients/2022/john.jpg) no-repeat center center;
    background-size: cover; }

.hcp-home-header {
  border-bottom: 1.5rem solid #004b87;
  padding: 0; }
  .hcp-home-header h1 {
    color: white;
    font-size: 1.5rem;
    margin: 0;
    padding: 1rem 0;
    text-align: center; }
  .hcp-home-header .replace {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-top: clamp(1rem, 5vw, 1.75rem);
    padding-bottom: clamp(0.75rem, 3.75vw, 1.25rem); }
    .hcp-home-header .replace img {
      width: clamp(240px, 80vw, 30rem);
      height: auto; }
  .hcp-home-header .approved {
    padding-bottom: clamp(1.75rem, 5vw, 2.5rem); }
    @media (min-width: 576px) {
      .hcp-home-header .approved {
        display: flex;
        align-items: center;
        justify-content: center; } }
  .hcp-home-header .hiker {
    display: flex;
    justify-content: center; }
    .hcp-home-header .hiker img {
      display: block;
      width: clamp(180px, 50vw, 20rem);
      height: auto; }
  .hcp-home-header .logo {
    display: grid;
    justify-content: center;
    margin: 0 0 1rem 0; }
    .hcp-home-header .logo img {
      display: block;
      width: clamp(180px, 80vw, 14rem);
      height: auto; }
  .hcp-home-header h2 {
    margin: 0 0 0.75rem;
    text-align: center; }
    @media (min-width: 576px) {
      .hcp-home-header h2 {
        font-size: clamp(1.25rem, 3.5vw, 1.5rem);
        text-align: left; } }
  .hcp-home-header .hiker-list {
    color: #004b87;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: clamp(1rem, 5vw, 1.25rem);
    margin: 0 auto;
    padding-left: 0; }
    @media (min-width: 576px) {
      .hcp-home-header .hiker-list {
        display: block;
        padding-left: 1.5rem; }
        .hcp-home-header .hiker-list li:nth-child(2) {
          margin-left: 1.5rem; }
        .hcp-home-header .hiker-list li:nth-child(3) {
          margin-left: 3rem; } }
  .hcp-home-header h3 {
    color: #004b87;
    padding: 0 1rem 1rem;
    padding-bottom: clamp(1rem, 5vw, 1.75rem);
    text-align: center; }

.btn-arrow-right a {
  background-color: #00aeef;
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
  color: white;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  padding: 1rem 1.5rem 1rem 1rem;
  text-align: center;
  text-decoration: none !important;
  transition: all ease-in 0.2s; }
  .btn-arrow-right a:hover {
    background-color: #004b87;
    color: white; }
  @media (min-width: 576px) {
    .btn-arrow-right a {
      font-size: 1rem; } }
  @media (min-width: 992px) {
    .btn-arrow-right a {
      font-size: 1.125rem; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .btn-arrow-right a {
      background: url("/img/home-cta-right.svg") no-repeat left top;
      background-size: 100% 100%; } }

.hcp-home-support .pox-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 8em; }

.hcp-home-support .pox {
  width: 100%;
  max-height: 8em; }

.fxd-note .icon {
  position: absolute;
  left: -1.5em;
  top: -1.5em; }

@media (min-width: 768px) {
  .fxd-note {
    position: relative;
    left: -25%;
    top: -12.5em; } }

@media (min-width: 768px) {
  .hcp-fxd-additional-symptoms {
    margin-top: -9em; } }

.hcp-fxd-pct {
  position: absolute;
  top: -1.375em;
  left: 50%;
  transform: translateX(-50%); }
  @media (min-width: 576px) {
    .hcp-fxd-pct {
      left: -1.75em;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 768px) {
    .hcp-fxd-pct {
      left: -2.25em; } }

#chartModal .modal-header {
  position: relative; }

#chartModal .btn-close {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000104;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }
  #chartModal .btn-close:hover {
    opacity: 1; }

.severity-level {
  margin: 1rem 0; }
  @media (min-width: 576px) {
    .severity-level {
      margin: 1rem 2rem; } }
  @media (min-width: 768px) {
    .severity-level {
      margin: 1rem 0; } }
  .severity-level .severity-heading {
    border-radius: 0.5rem;
    font-size: 6.25vw;
    font-weight: 700;
    margin: 0;
    padding: 0.75em 0 0.5em 53%; }
    @media (min-width: 768px) {
      .severity-level .severity-heading {
        font-size: 2.5vw; } }
    @media (min-width: 992px) {
      .severity-level .severity-heading {
        font-size: 24px; } }
  .severity-level .range {
    border-radius: 0.5rem;
    font-size: 6.25vw;
    line-height: 1.25;
    margin: 0;
    padding: 0.5em 0.25em 0.5em 53%; }
    @media (min-width: 768px) {
      .severity-level .range {
        font-size: 2vw; } }
    @media (min-width: 992px) {
      .severity-level .range {
        font-size: 24px; } }
  .severity-level .bg-mild {
    background-color: #ffc107; }
  .severity-level .bg-mod {
    background-color: #fd7e14; }
  .severity-level .bg-severe {
    background-color: #b32028; }
  .severity-level .severity-icon {
    display: block;
    position: absolute;
    top: 0;
    left: -4%;
    width: 55%;
    max-width: 270px;
    height: auto; }
    @media (min-width: 992px) {
      .severity-level .severity-icon {
        top: -2rem;
        left: -8%;
        width: 62.55%; } }
  .severity-level p {
    line-height: 1.25;
    margin: 1rem 0;
    padding-left: 20%; }

.hcp-highest-content .vial {
  z-index: 1; }

.hcp-highest-content .banner {
  border-top: 6px solid #004b87;
  border-bottom: 6px solid #004b87;
  line-height: 1.25; }
  @media (min-width: 576px) {
    .hcp-highest-content .banner {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .hcp-highest-content .banner {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .hcp-highest-content .banner {
      font-size: 1.375rem; } }

@media (min-width: 576px) {
  .hcp-support-nav .pox-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 8em;
    width: 100%; } }

@media (max-width: 991.98px) {
  .hcp-resources #access {
    font-size: 0.875rem; } }

.text-survey-magenta {
  color: #e41b6b !important; }

.survey-blood-drop {
  display: flex;
  background-image: url(/img/survey-2022/blood-drop.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 2.5em;
  height: 3em; }

.survey-table th {
  border-bottom: 4px solid #003d6e;
  color: #003d6e;
  padding: 0.5em 0; }

.survey-table td {
  padding: 1.5em 0; }

.survey-table .border-top {
  border-top: 1px dotted #5f6c7f; }

@media (min-width: 992px) {
  .col-qol {
    flex: 62.5% !important;
    max-width: 62.5% !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .col-hwbi {
    flex: 37.5% !important;
    max-width: 37.5% !important;
    padding-left: 2% !important; } }

.col-hwbi .img-fluid {
  max-width: 50%; }
  @media (min-width: 992px) {
    .col-hwbi .img-fluid {
      max-width: 100%; } }

.pi-frame {
  border: 1px solid #808998;
  display: grid;
  width: calc(95vw - 18px) !important;
  max-width: 850px;
  height: 50vh;
  margin: 0 auto; }
  @media (min-width: 576px) {
    .pi-frame {
      height: auto;
      aspect-ratio: 8.5/5.5; } }
