.hcp-home-header {
	border-bottom: 1.5rem solid $primary;
	padding: 0;
	
	h1 {
		color: white;
		font-size: 1.5rem;
		margin: 0;
		padding: 1rem 0;
		text-align: center;
	}
	
	.replace {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		padding-top: clamp(1rem, 5vw, 1.75rem);
		padding-bottom: clamp(0.75rem, 3.75vw, 1.25rem);
		
		img {
			width: clamp(240px, 80vw, 30rem);
			height: auto;
		}
	}
	
	.approved {
		padding-bottom: clamp(1.75rem, 5vw, 2.5rem);
		
		@include media-breakpoint-up(sm) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	
	.hiker {
		display: flex;
		justify-content: center;
		
		img {
			display: block;
			width: clamp(180px, 50vw, 20rem);
			height: auto;
		}
	}
	
	.logo {
		display: grid;
		justify-content: center;
		margin: 0 0 1rem 0;
		
		img {
			display: block;
			width: clamp(180px, 80vw, 14rem);
			height: auto;
		}
	}
	
	h2 {
		margin: 0 0 0.75rem;
		text-align: center;
		
		@include media-breakpoint-up(sm) {
			font-size: clamp(1.25rem, 3.5vw, 1.5rem);
			text-align: left;
		}
	}
	
	.hiker-list {
		color: $primary;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: clamp(1rem, 5vw, 1.25rem);
		margin: 0 auto;
		padding-left: 0;
		
		@include media-breakpoint-up(sm) {
			display: block;
			padding-left: 1.5rem;
			
			li:nth-child(2) {
				margin-left: 1.5rem;
			}
			li:nth-child(3) {
				margin-left: 3rem;
			}
		}
	}
	
	h3 {
		color: $primary;
		padding: 0 1rem 1rem;
		padding-bottom: clamp(1rem, 5vw, 1.75rem);
		text-align: center;
	}
}



.btn-arrow-right a {
	background-color: $cyan;
	clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
	color: white;
	display: block;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.25;
	padding: 1rem 1.5rem 1rem 1rem;
	text-align: center;
	text-decoration: none !important;
	transition: all ease-in 0.2s;
	
	&:hover {
		background-color: $primary;
		color: white;
	}
	
	@include media-breakpoint-up(sm) {
		//clip-path: polygon(10% 0, 100% 0, 100% 100%, 10% 100%, 0 50%);
		font-size: 1rem;
		//margin-bottom: 1rem;
		//padding: 0.75rem 1rem 0.75rem 2.5rem;
	}
	
	@include media-breakpoint-up(lg) {
		font-size: 1.125rem;
		//padding: 1rem 1rem 1rem 2.5rem;
	}
	
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		// IE10+ CSS here
		
		background: url("/img/home-cta-right.svg") no-repeat left top;
		background-size: 100% 100%;
		
	}
}


.hcp-home-support {
	.pox-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 8em;
	}
	
	.pox {
		width: 100%;
		max-height: 8em;
	}
}