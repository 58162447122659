.hcp-highest-content {
	.vial {
		z-index: 1;
	}
	
	.banner {
		border-top: 6px solid $primary;
		border-bottom: 6px solid $primary;
		line-height: 1.25;
		
		@include media-breakpoint-up(sm) {
			font-size: 1.125rem;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: 1.25rem;
		}
		
		@include media-breakpoint-up(xl) {
			font-size: 1.375rem;
		}
	}
}