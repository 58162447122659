 
@import "custom/custom-variables";

/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
//@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
//@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
//@import "bootstrap/navbar";
//@import "bootstrap/card";
//@import "bootstrap/breadcrumb";
//@import "bootstrap/pagination";
//@import "bootstrap/badge";
//@import "bootstrap/jumbotron";
//@import "bootstrap/alert";
//@import "bootstrap/progress";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
@import "bootstrap/close";
//@import "bootstrap/toasts";
@import "bootstrap/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
//@import "bootstrap/carousel";
//@import "bootstrap/spinners";
@import "bootstrap/utilities";
@import "bootstrap/print";




//////////// Custom components //////////////

@import "custom/section";
@import "custom/type";
@import "custom/icons";
@import "custom/header";
@import "custom/search";
@import "custom/footer";
@import "custom/home";
@import "custom/utility";
@import "custom/rare-bleeding-disorders";
@import "custom/factor-x-deficiency";
@import "custom/diagram";
@import "custom/fxd";
@import "custom/tables";
@import "custom/why";
@import "custom/dosing-app";
@import "custom/clinical-results";
@import "custom/faq";
@import "custom/awareness-modal";
@import "custom/pox";
@import "custom/patient-stories";
@import "custom/hcp-home";
@import "custom/hcp-hfxd";
@import "custom/hcp-why";
@import "custom/hcp-support";
@import "custom/hcp-survey";
@import "custom/pi";