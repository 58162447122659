.enroll {
	
	background: url(../img/hiker-2022-sm.jpg) no-repeat center center $primary;
	background-size: cover;
	min-height: 70vw;
	
	
	.hiker {
		padding: 2.5vw 0 0 2.5vw;
		
	}
	
	h1 {
		font-size: 5vw;
		line-height: 1.1;
		text-shadow: 1px 1px 0 $primary;
	}
	
	p {
		font-size: 4.0625vw;
		line-height: 1.333334;
	}
	
	@include media-breakpoint-up(md) {
		background: url(../img/hiker-2022.jpg) no-repeat right center $primary;
		background-size: cover;
		
		p {
			font-size: 3.90625vw;
		}
	}
	
	@include media-breakpoint-up(xl) {
		min-height: 58vw;
		
		.hiker {
			padding: 1.5rem 0 0 1.5rem;
		}
		
		h1 {
			font-size: 2.5rem;
		}
		
		p {
			font-size: 2rem;
		}
	}
	
	@media screen and (min-width: 1472px) {
		min-height: 800px;
	}
	
}