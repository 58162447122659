.pi-frame {
	border: 1px solid $gray-400;
	display: grid;
	width: calc(95vw - 18px)!important;
	max-width: 850px;
	height: 50vh;
	// aspect-ratio: 8.5/11;
	margin: 0 auto;
	
	@include media-breakpoint-up(sm) {
		height: auto;
		aspect-ratio: 8.5/5.5;
	}
}
