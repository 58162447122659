.home-hero {
	
	padding-top: 0;
	//padding-bottom: 13.333333vw;
	position: relative;
	
	&:after {
		content: "";
		display: block;
		background: url(../img/swoosh.svg) repeat-x 58% bottom;
		//background-size: 400vw;
		background-size: 1500px;
		padding-bottom: 11.666668vw;
		position: absolute;
		left: 0;
		bottom: 18px;
		width: 100%;
		
		@media screen and (min-width: 600px) {
			background-position: calc(62% + 17vw) bottom;
			background-size: 2400px;
			bottom: 22px;
			padding-bottom: 70px;
		}
		
		@media screen and (min-width: 1472px) {
			background-position: calc(50% + 150px) bottom;
		}
	}
}

.home-hero-container {
	background: url(../img/hiker-2022-sm.jpg) no-repeat;
	background-size: cover;
	background-position: center center;
	max-width: 1472px;
	margin: 0 auto;
	//min-height: 62vw;
	
	@media screen and (min-width: 600px) {
		//background: url(../img/hiker-2022-sm.jpg) no-repeat;
		//background-size: cover;
		//background-position: 100% 50%;
	}
	
	@include media-breakpoint-up(md) {
		background: url(../img/hiker-2022.jpg) no-repeat;
		background-size: cover;
		background-position: right center;
	}
}

.home-hero-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 62vw;
	margin: 0;
	padding-left: 52%;
	padding-right: 1rem;
	
	h1 {
		margin: 0 0 0.5rem 0;;
		width: 100%;
		
		span {
			background: url(../img/replace-exactly.svg) no-repeat left top;
			background-size: 100%;
			display: block;
			padding-bottom: 12.5vw;
			width: 42vw;
		}
	}
	
	h2 {
		font-size: 3.466667vw;
		margin: 0;
	}
	
	.find-fast {
		font-size: 0.875rem;
		margin: 0 1rem 1rem;
		width: 100%;
		z-index: 1;
	}
	
	.find-fast-panel {
		box-shadow: 0.25rem 0.25rem 0.25rem rgba(0,1,4,.15)!important;
	}
	
	@media screen and (min-width: 600px) {
		min-height: 60vw;
		padding: 0 1rem 0 56%;
		width: 100%;
		
		h1 {
			
			span {
				padding-bottom: 26.037736%;
				position: static;
				top: auto;
				transform: unset;
				width: 100%;
			}
		}
		
		h2 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 0.5rem;
			width: 100%;
		}
		
		.find-fast {
			display: block;
			height: 33.263876%;
			margin-left: 0;
			margin-right: 0;
		}
	}
	
	@include media-breakpoint-up(md) {
		
		padding: 0 1rem 0 61%;
		
		h2 {
			font-size: 1.953125vw;
		}
		
		.find-fast {
			font-size: 1rem;
			padding-bottom: 2.5rem;
		}
		
		.find-fast-panel {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}
	}
	
	@media screen and (min-width: 1472px) {
		
		height: 812px;
		min-height: 812px;
		padding: 0 0 0 900px;
		
		h2 {
			font-size: 1.25rem
		}
	}
	
}

.home-cta {
	padding-right: 1rem;
	padding-bottom: 1rem;
	
	@media screen and (min-width: 600px) {
		display: flex;
		flex-direction: column;
		height: 14%;
		justify-content: center;
		padding: 0;
	}
	
}

.home-cta-btn {
	background-color: $cyan;
	clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
	color: white;
	display: block;
	font-size: calc(1.2875rem + .45vw);;
	font-weight: 700;
	line-height: 1.25;
	padding: 3.25vw;
	text-align: center;
	text-decoration: none !important;
	transition: all ease-in 0.2s;
	
	&:hover {
		background-color: $primary;
		color: white;
	}
	
	@media screen and (min-width: 600px) {
		font-size: 1.953125vw;
		padding: 3.472222% 6.944444% 3.472222% 3.472222%;
	}
	
	@include media-breakpoint-up(xl) {
		font-size: 1.25rem;
	}
	
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		// IE10+ CSS here
		
		background: url("/img/home-cta-right.svg") no-repeat left top;
		background-size: 100% 100%;
		
	}
}


