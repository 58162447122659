.fxd-note {
	
	
	.icon {
		position: absolute;
		left: -1.5em;
		top: -1.5em;
	}
	
	@include media-breakpoint-up(md) {
		position: relative;
		left: -25%;
		top: -12.5em;
	}
	
}

.hcp-fxd-additional-symptoms {
	@include media-breakpoint-up(md) {
		margin-top: -9em;
	}
}

.hcp-fxd-pct {
	position: absolute;
	top: -1.375em;
	left: 50%;
	transform: translateX(-50%);
	
	@include media-breakpoint-up(sm) {
		left: -1.75em;
		top: 50%;
		transform: translateY(-50%);
	}
	
	@include media-breakpoint-up(md) {
		left: -2.25em;
	}
}

#chartModal {
	
	.modal-header {
		position: relative;
	}
	
	.btn-close {
		background-color: transparent;
		border: none;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1;
		color: #000104;
		text-shadow: 0 1px 0 #fff;
		opacity: .5;
		padding: 1rem;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		
		&:hover {
			opacity: 1;
		}
	}
}

.severity-level {
	margin: 1rem 0;
	
	@include media-breakpoint-up(sm) {
		margin: 1rem 2rem;
	}
	
	@include media-breakpoint-up(md) {
		margin: 1rem 0;
	}
	
	
	.severity-heading {
		border-radius: $border-radius;
		font-size: 6.25vw;
		font-weight: 700;
		margin: 0;
		padding: 0.75em 0 0.5em 53%;
		//text-align: center;
		
		@include media-breakpoint-up(md) {
			font-size: 2.5vw;
			//padding: 1em 1em 1em 45%;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: 24px;
		}
	}
	
	.range {
		border-radius: $border-radius;
		font-size: 6.25vw;
		line-height: 1.25;
		margin: 0;
		padding: 0.5em 0.25em 0.5em 53%;
		//text-align: center;
		
		@include media-breakpoint-up(md) {
			font-size: 2vw;
			//padding: 1em 1em 1em 45%;
		}
		
		@include media-breakpoint-up(lg) {
			font-size: 24px;
		}
	}
	
	.bg-mild {
		background-color: $yellow;
	}
	
	.bg-mod {
		background-color: $orange;
	}
	
	.bg-severe {
		background-color: $red;
	}
	
	.severity-icon {
		display: block;
		position: absolute;
		top: 0;
		left: -4%;
		width: 55%;
		max-width: 270px;
		height: auto;
		
		@include media-breakpoint-up(md) {
			//top: 0.75rem;
			//left: -5%;
			//width: 62.55%;
		}
		
		@include media-breakpoint-up(lg) {
			top: -2rem;
			left: -8%;
			width: 62.55%;
		}
	}
	
	p {
		line-height: 1.25;
		margin: 1rem 0;
		padding-left: 20%;
		
		@include media-breakpoint-up(md) {
			//margin: 0 0 1rem 0;
		}
	}
}
