.why-coagadex-tables {
	
	@include media-breakpoint-up(sm) {
		.col-age {
				width: 18%;
			}
			
			.col-dose {
				width: 27%;
			}
	}
	
}

.inactivation-steps {
	align-items: center;
	flex-direction: column;
	width: 80%;
	max-width: 240px;
	
	.step {
		text-align: left;
		
		&:not(.step-4) {
			figcaption {
				padding-left: calc(1.25em + 4px);
			}
		}
	}
	
	.step-number {
		display: inline-block;
		margin-left: calc(-1.25em - 4px);
		width: 1.25em;
	}
	
	.arrow {
		margin: 0 auto;
		transform: rotate(90deg);
		width: 50px;
	}
	
	@include media-breakpoint-up(md) {
		align-items: flex-start;
		flex-direction: row;
		width: 82.5%;
		max-width: unset;
		
		.step {
			width: 17.5vw;
		}
		
		.arrow {
			align-items: center;
			display: flex;
			height: 17.5vw;
			transform: none;
			width: 4.587156vw;
		}
	}
	
	@include media-breakpoint-up(xl) {
		width: 900px;
		
		.step {
			width: 192px;
		}
		
		.arrow {
			height: 192px;
			width: 50px;
		}
	}
}