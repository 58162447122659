@include media-breakpoint-up(lg) {
  html {
	font-size: 1.125rem;
  }
}

@include media-breakpoint-up(xl) {
  html {
	font-size: 1.25rem;
  }
}


h1 {
	color: $primary;
	margin-bottom: 1em;
}

h2 {
	color: $primary;
	margin-bottom: 0.75em;
}

.text-xxs {
	font-size: 11px;
}

.text-xs {
	font-size: 0.75rem;
}

.text-sm {
	font-size: 0.875rem;
}

.text-base {
	font-size: 1rem;
}

.text-lg {
	font-size: 1.125rem;
}

.text-xl {
	font-size: 1.25rem;
}

.text-2xl {
	font-size: 1.5rem;
}

.text-3xl {
	font-size: 1.875rem;
}

.text-4xl {
	font-size: 2.25rem;
}



ul.bullet {
	
	list-style: none;
	
	> li {
		margin-bottom: 0.5em;
		
		&:before {
			content: "•";
			//color: $primary;
			display: inline-block;
			font-size: 22px;
			font-weight: bold;
			line-height: 22px;
			margin-left: -0.8em;
			vertical-align: top;
			width: 0.8em;
		}
	}
}

ul.dash {
	list-style: none;
	padding-left: 1.5em;
	
	> li {
	
		margin: 0.25em 0;
	
		&:before {
			content: "\2014";
			display: inline-block;
			margin-left: -1.5em;
			width: 1.5em;
		}
	}
}

// utility classes to add vertical margins between list items
ul, ol {
	&.item-mb-1 {
		> li {
			margin-bottom: 0.25rem;
		}
	}
	&.item-mb-2 {
		> li {
			margin-bottom: 0.5rem;
		}
	}
	&.item-mb-3 {
		> li {
			margin-bottom: 1rem;
		}
	}
}



a.text-underline,
main a:not(.btn) {
	text-decoration: underline;
	
	
		&:hover {
			text-decoration: none !important;
		}
}

.font-style-normal {
	font-style: normal !important;
}


// columns
.columns-sm-2 {
	@include media-breakpoint-up(sm) {
		columns: 2 !important;
	}
}

.columns-md-3 {
	@include media-breakpoint-up(md) {
		columns: 3 !important;
	}
}


// hanging indents
.hang-1 {
	display: inline-block;
	margin-left: calc(-1em - 3px);
	padding-right: 3px;
	width: calc(1em + 3px);
}

// make superscripts/subscripts stand out less for headings, etc.
sup, sub {
	&.muted {
		font-size: 60%;
		font-weight: 400;
		margin-left: 0.125rem;
	}
}


.a-underline {
	text-decoration: underline;
	text-decoration-skip-ink: auto;
	text-decoration-thickness: 1px;
	text-underline-offset: 0.15em;
	
	&:hover {
		text-decoration: none;
	}
}

// responsive text-align
@include media-breakpoint-up(sm) {
	.text-sm-center {
		text-align: center;
	}
}
@include media-breakpoint-up(md) {
	.text-md-center {
		text-align: center;
	}
}
@include media-breakpoint-up(lg) {
	.text-lg-center {
		text-align: center;
	}
}