.patient-intro-header {
		
	@include media-breakpoint-up(xl) {
		font-size: 0.875rem;
	}
}

.patient-intro-link {
	background: $info;
	display: block;
	padding: 88% 0 1rem 0;
	text-align: center;
	
	&.olivia {
		background: $info url(../img/patients/2022/olivia.jpg) no-repeat center center;
		background-size: cover;
	}
	
	&.carly {
		background: $info url(../img/patients/2022/carly.jpg) no-repeat center center;
		background-size: cover;
	}
	
	&.leah {
		background: $info url(../img/patients/2022/leah.jpg) no-repeat center center;
		background-size: cover;
	}
	
	&.john {
		background: $info url(../img/patients/2022/john.jpg) no-repeat center center;
		background-size: cover;
	}
}