.symptoms-list {
	li {
		line-height: 1.25;
	}
}


.doc-symptoms {
	@include media-breakpoint-up(md) {
		padding-right: 20%;
	}
}

.symptom-checker {
	
	img {
		max-width: 13rem;
	}
	
	@include media-breakpoint-up(md) {
		margin-top: -7rem;
	}
	
	@include media-breakpoint-up(md) {
		margin-top: -8.75rem;
	}
	
}